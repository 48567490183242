import { RouteRecordRaw } from "vue-router"
import DashboardView from "@/views/logged/dashboard/DashboardView.vue"

const dashboardRoutes: RouteRecordRaw[] = [
    {
        name: "dashboard",
        path: "dashboard",
        component: DashboardView,
        meta: { requireAuth: true, }
    },
]

export default dashboardRoutes