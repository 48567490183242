import { ConnectionStatus } from "@capacitor/network";
import useNetworkStatus from "./useNetworkStatus";
import { onMounted } from "vue";
import { useToast } from "vue-toastification";

/**
 * Hook that utilizes the `useNetworkStatus` hook to monitor network status changes
 * and display an error message using toast notifications when the network connection is lost.
 * 
 * This hook automatically checks the network status when the component is mounted and sets up
 * a listener to display a toast error message whenever the network status changes to disconnected.
 */
const useMissingNetworkAlert = () => {
    const toast = useToast()

    /**
     * Displays an error toast notification based on the network connection status.
     * 
     * @param networkStatus - The current network status, including the connection state.
     */
    const displayErrorOnConnectionError = (networkStatus: ConnectionStatus) => {
        if (networkStatus.connected)
            return

        toast.error('The application has lost internet access.')
    }

    const { getCurrentNetworkStatus } = useNetworkStatus({
        onNetworkStatusChange: displayErrorOnConnectionError,
    })

    onMounted(async () => {
        const networkStatus = await getCurrentNetworkStatus()

        displayErrorOnConnectionError(networkStatus)
    })
};

export default useMissingNetworkAlert;
