import { reactive } from "vue"

interface props {
    types: {
        [key: string]: string
    }
}

const useModals = ({ types: modalTypes }: props) => {
    const isModalOpenState = reactive({
        [modalTypes.COMBINATIONS]: false,
        [modalTypes.PACKAGES]: false,
    })

    type TModalName = typeof modalTypes[keyof typeof modalTypes] 

    const isModalOpen = (modalName: TModalName) => isModalOpenState[modalName]
    const closeModal = (modalName: TModalName) => isModalOpenState[modalName] = false
    const openModal = (modalName: TModalName) => isModalOpenState[modalName] = true

    return {
        isModalOpen,
        closeModal,
        openModal,
    }
}

export default useModals;