import { ref } from "vue";
import { useRouter } from 'vue-router';
import useDocumentApi from '@/composable/api/http/useDocumentApi';
import { useToast } from "vue-toastification";

const useCloseStocktakingDocumentAlert = (
    documentId: number,
) => {
    const isOpenAlert = ref(false);

    const { markClosedDocument } = useDocumentApi();
    const router = useRouter()
    const toast = useToast();

    const closeCloseDocumentAlert = () => isOpenAlert.value = false;
    const openCloseDocumentAlert = () => isOpenAlert.value = true

    const alertButton = [
        {
            text: 'Cancel',
            role: 'cancel',
        },
        {
            text: 'OK',
            role: 'confirm',
            handler: async () => {
                const markDocumentAsClosed = await markClosedDocument(documentId);

                if (markDocumentAsClosed.message == "Sucessfully closed document") {
                    router.push({name : 'stocktaking/show-document', params: { id: documentId }})
                    toast.success("Document closed")
                }
            },
        }
    ];

    return {
        alertButton,
        isOpenAlert,
        closeCloseDocumentAlert,
        openCloseDocumentAlert,
    }
}

export default useCloseStocktakingDocumentAlert;