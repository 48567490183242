import { ComputedRef, ref, unref } from "vue";
import { useToast } from "vue-toastification";
import { IScannedArticle } from "@/const/types/article";
import usePackageApi from "./api/http/usePackageApi";
import { TPackage } from "@/const/types/package";
import { TPlacementsRelatedForZDCompact } from "@/const/types/documents";
import { tryCatchAsync } from "@/utils/tryCatchAsync";

type TListProxy = TPlacementsRelatedForZDCompact[] | ComputedRef<TPlacementsRelatedForZDCompact[]> 

const useScanningList = (listProxy: TListProxy, hasToBeOnList = true) => {

    const isLoading = ref(false)
    const scannedArticles = ref<IScannedArticle[]>([])
    const scannedPackages = ref<TPackage[]>([])

    const toast = useToast()
    const { showPackage } = usePackageApi()

    const addProductToScannedList = async (code: string) => {
        if(isLoading.value) return

        isLoading.value = true
        const list = unref(listProxy)

        let capacity = 1
        let article = list.find(article => article.code == code)
        let articleCode = code

        if (!article) {
            const packageItem = scannedPackages.value.find(item => item.code == code)

            if (packageItem) {
                const packageItemId = packageItem.combination_id ?? packageItem.product_id

                article = list.find(article => article.id == packageItemId)

                capacity = packageItem.capacity
                articleCode = article.code
            }
        }

        if (!article) {
            const [packageItem, _] = await tryCatchAsync(() => showPackage(code, { findKey: 'code' }))

            if (packageItem) {
                const packageItemId = packageItem.combination_id ?? packageItem.product_id

                article = list.find(article => article.id == packageItemId)

                if (article) {
                    capacity = packageItem.capacity
                    articleCode = article.code

                    scannedPackages.value.push(packageItem)
                }
            }
        }

        if (!article && hasToBeOnList) {
            toast.error("Scanned item not found on entry document.")
            isLoading.value = false
            return
        }

        if (!changeArticleQuantity(articleCode, capacity)) {
            scannedArticles.value.push({ code: articleCode, quantity: capacity, connectedArticle: article })
        }

        isLoading.value = false
    }

    const deleteArticleQuantity = (articleCode: string) => {
        const scannedArticleIndex = scannedArticles.value.findIndex((article) => article.code == articleCode) 

        if (scannedArticleIndex >= 0) {
            scannedArticles.value.splice(scannedArticleIndex, 1)
            return true
        }
    }

    const changeArticleQuantity = (articleCode: string, amountToChange: number) => {
        const scannedArticleIndex = scannedArticles.value.findIndex((article) => article.code == articleCode) 
        const scannedArticle = scannedArticles.value[scannedArticleIndex] 

        if (scannedArticleIndex >= 0 && amountToChange > 0) {
            scannedArticle.quantity += amountToChange
            return true
        }
        
        if (scannedArticleIndex >= 0) {
            const newAmount = scannedArticle.quantity += amountToChange

            if (newAmount <= 0) {
                scannedArticles.value.splice(scannedArticleIndex, 1)
            } else {
                scannedArticle.quantity = newAmount
            }

            return true
        }

        return false
    }

    const setArticleQuantity = (articleCode: string, amountToChange: number) => {
        const scannedArticleIndex = scannedArticles.value.findIndex((article) => article.code == articleCode) 
        const scannedArticle = scannedArticles.value[scannedArticleIndex] 

        if (scannedArticleIndex >= 0 && Number(amountToChange) > 0) {
            scannedArticle.quantity = Number(amountToChange)
            return true
        }
        
        return false
    }

    return {
        isLoading,
        scannedArticles,
        setArticleQuantity,
        changeArticleQuantity,
        deleteArticleQuantity,
        addProductToScannedList,
    }
}

export default useScanningList