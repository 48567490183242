import useDocumentsLoader from '@/composable/useDocumentsLoader';
import { TDocument } from '@/const/types/documents';
import useUserStore from "@/store/user";
import { computed, onMounted, watch } from "vue";
import { useRouter } from "vue-router";

const filterParams = {
    calculateDaysUntilDelivery: true,
    filters: { type: 'AS', is_active: 1 },
    sort: { days_until_delivery: 'asc' },
    with: ['attributes', 'aggregatedPlacements', 'referencedDocuments', 'user'],
};

const filterTypes = [{ type: 'document', key: 'code' }];

const useGetEntryCensusSheetDocuments = (parentDocumentId) => {
    const router = useRouter();
    const onFoundExactOneDocument = (document: TDocument) => {
        return router.push({ name: 'stocktaking/register-placements', params: { id: document.id } })
    }
    const userStore = useUserStore();

    watch(() => userStore.state.user, (user) => {
        resetSearch()
    })

    onMounted(() => {
        if (userStore.state.user)
            resetSearch()
    })

    const updatedFilterParams = computed(() => ({
        ...filterParams,
        filters: {
            ...filterParams.filters,
            referenced_document_id: parentDocumentId,
        },
    }));

    const {
        loadMore,
        documents,
        maxDocuments,

        foundType,
        searchCode,
        resetSearch,
    } = useDocumentsLoader<'document'>({
        filterParams: updatedFilterParams,
        filterTypes,
        onFoundExactOneDocument,
        dontLoadOnMounted: true,
    });

    return {
        loadMore,
        documents,
        maxDocuments,

        foundType,
        searchCode,
    }
}

export default useGetEntryCensusSheetDocuments;
