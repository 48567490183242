import { TDocument, TDocumentRelations } from "@/const/types/documents";
import { BasicIndexRequestParams, BasicShowRequestParams } from "@/const/types/endpoint";
import useApi from "./useApi";

interface IIndexDocumentsParams extends BasicIndexRequestParams {
  with?: TDocumentRelations[];
}

interface IShowDocumentsParams extends BasicShowRequestParams {
  with?: TDocumentRelations[];
}

interface IStoreDocumentParams {
  setNewAvailableCode?: boolean;
  setNameBasedOnCode?: boolean;
}

const useDocumentApi = () => {

  const { fetchOne, fetchAll, createOne, markCloseOne } = useApi<TDocument>('documents');

  /**
   * GET /index - returns all document based on provided params.
   * 
   * @doc https://unlimitech.stoplight.io/docs/wms/branches/main/c1a73911f74e4-display-documents
   */
  const displayDocuments = async (params: IIndexDocumentsParams) => {
    try {
      const documents = await fetchAll<IIndexDocumentsParams>({ params })

      return documents
    } catch (error) {
      throw error
    }
  }

  /**
   * GET /index/id - returns all specific information about document based on provided params.
   * 
   * @doc https://unlimitech.stoplight.io/docs/wms/branches/main/a239b14b56d5c-show-one-document
   */
  const showDocument = async (key: string | number, params: IShowDocumentsParams): Promise<TDocument | null> => {
    try {
      const { data: document } = await fetchOne<IShowDocumentsParams>({ key, params })

      return document
    } catch (error) {
      throw error
    }
  }
  /**
   * post /documents - create new document.
   * 
   * @doc https://unlimitech.stoplight.io/docs/wms/branches/main/d741d3f4161de-utworz-dokumenty
   */
  const createDocument = async (body: Partial<TDocument>, meta: IStoreDocumentParams = {}) => {
    try {
      const { data } = await createOne({
        body: {
          ...body,
          meta,
        }
      })

      return data;
    } catch (error) {
      throw error
    }
  }

  /**
   * post /documents - create new document.
   * 
   * @doc https://unlimitech.stoplight.io/docs/wms/branches/main/d741d3f4161de-utworz-dokumenty
   */
  const markClosedDocument = async (key: string | number) => {
    try {
      const data = await markCloseOne({ key })
      return data;
    } catch (error) {
      throw error
    }
  }
  return {
    showDocument,
    displayDocuments,
    createDocument,
    markClosedDocument
  }
}

export default useDocumentApi