import { useRoute } from 'vue-router';
import { computed, onMounted, ref } from 'vue';

/* Components */
import PlaceDetailsView from './../scanned/PlaceDetailsView.vue'
import ScannedNotFoundArticle from './../scanned/NotFoundArticle.vue'
import { TPlace } from '@/const/types/place';
import usePlaceApi from '@/composable/api/http/usePlaceApi';

const useScanPlaceResult = () => {
    const route = useRoute();
    const { showPlace } = usePlaceApi()

    const placeData = ref<TPlace | null>(null);

    const errorMsg = ref('');
    const isLoading = ref(false);

    const placeCode = computed(() => (route.params.code as string | null) ?? '');
    const resultViewComponent = computed(() => {
        switch(true) {
            case (!isLoading.value && !errorMsg.value && placeData.value != null):
                return PlaceDetailsView
            case (!isLoading.value):
                return ScannedNotFoundArticle
            default:
                return null
        }
    })

    const findPlace = async () => {
        isLoading.value = true;
        errorMsg.value = ""

        try {
            placeData.value = await showPlace(placeCode.value, { findKey: 'code', with: ['aggregatedPlacements.product', 'aggregatedPlacements.combination', 'aggregatedPlacements.combination.product'] })
        } catch (error) {
            console.error(error)

            const errorData = error.data as { message?: string };
            const errorMessage = errorData.message ?? 'Error occures at loading product info.';

            errorMsg.value = errorMessage;
        } finally {
            isLoading.value = false;
        }
    }

    onMounted(() => { findPlace() });

    return {
        placeData,
        placeCode,
        resultViewComponent,
        form: {
            isLoading,
            errorMsg,
        },
    }
}

export default useScanPlaceResult