import { RouteLocationRaw } from "vue-router";

export type TMenuItem = {
    groupName: string;
    label: string;
    image: string;
    route: RouteLocationRaw;
    meta: {
        hideInDashboard?: boolean,
    },
}

const menuItems: TMenuItem[] = [
    {
        groupName: 'Dashboard',
        label: 'Dashboard',
        image: require('./../assets/icon/scan-icon.svg'),
        route: { name: 'dashboard' },
        meta: {
            hideInDashboard: true,
        } 
    },
    {
        groupName: 'Scan',
        label: 'Scan article',
        image: require('./../assets/icon/scan-icon.svg'),
        route: { name: 'scan/article-find' },
        meta: {},
    },
    {
        groupName: 'Scan',
        label: 'Scan place',
        image: require('./../assets/icon/scan-icon.svg'),
        route: { name: 'scan/place-find' },
        meta: {},
    },
    {
        groupName: 'Actions',
        label: 'Accept delivery',
        image: require('./../assets/icon/accept-delivery-icon.svg'),
        route: { name: 'delivery/select-entry-document' },
        meta: {},
    },
    {
        groupName: 'Actions',
        label: 'Move placements',
        image: require('./../assets/icon/move-placements-icon.svg'),
        route: { name: 'move-placements/select-articles' },
        meta: {},
    },
    {
        groupName: 'Actions',
        label: 'Stocktaking',
        image: require('./../assets/icon/stocktaking-icon.svg'),
        route: { name: 'stocktaking/select-articles' },
        meta: {},
    },
]

export default menuItems