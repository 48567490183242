import usePlaceApi, { IShowPlaceParams } from '@/composable/api/http/usePlaceApi';

import { TPlace } from '@/const/types/place';
import { tryCatchAsync } from '@/utils/tryCatchAsync';
import { ref } from 'vue';
import { useToast } from 'vue-toastification';

const showPlaceParams: IShowPlaceParams = {
    findKey: 'code',
    with: ['placements', 'children'],
}

const usePlaceSelection = (onSelectedPlaceChange?: (placeId: number) => void) => {
  const selectedPlace = ref<TPlace>(null);

  const toast = useToast();
  const { showPlace } = usePlaceApi();

  const selectPlace = async (code: string) => {
    const [placeValue, placeError] = await tryCatchAsync(() => showPlace(code, showPlaceParams));

    if (placeValue) {
      if ((placeValue.children ?? []).length > 0) {
        toast.error('This is not destined place, because it contains children.')
        return false
      }

      selectedPlace.value = placeValue;
      if (onSelectedPlaceChange) {
        onSelectedPlaceChange(placeValue.id)
      }
      return true;
    } else {
      if (!selectedPlace.value && placeError && placeError.status == 404) {
        toast.error('Place not found.')
      }
    }

    return false;
  };

  return {
    selectPlace,
    selectedPlace,
  };
};

export default usePlaceSelection;
