import { BasicIndexRequestParams, BasicShowRequestParams } from '@/const/types/endpoint';
import axios from '@libs/axios';

const useApi = <Model>(baseEndpoint: string) => {

    type TFetchOneResponse = { data: (Model | null), meta?: any; message?: string }
    type TFetchAllResponse = { data: (Model | null)[], meta?: any }
    type TStoreOneResponse = { data: (Model | null), meta?: any }

    const fetchOne = async <Params extends BasicShowRequestParams>({ endpoint, key, params }: {
        key: string | number,
        endpoint?: string,
        params?: Params
    }): Promise<TFetchOneResponse> => {

        const combinedEndpoint = endpoint ? `${baseEndpoint}/${endpoint}` : baseEndpoint

        try {
            const { data } = await axios.get<TFetchOneResponse>(`${combinedEndpoint}/${key}`, { params });

            return data;
        } catch (error) {
            throw error;
        }

    };

    const fetchAll = async <Params extends BasicIndexRequestParams>({ endpoint, params }: {
        endpoint?: string,
        params?: Params
    }): Promise<TFetchAllResponse> => {

        const combinedEndpoint = endpoint ? `${baseEndpoint}/${endpoint}` : baseEndpoint

        try {
            const { data } = await axios.get<TFetchAllResponse>(`${combinedEndpoint}`, { params });

            return data;
        } catch (error) {
            throw error;
        }

    };

    const createOne = async <Body>({ endpoint, body }: {
        endpoint?: string,
        body?: Body
    }): Promise<TStoreOneResponse> => {

        const combinedEndpoint = endpoint ? `${baseEndpoint}/${endpoint}` : baseEndpoint

        try {
            const { data } = await axios.post<TStoreOneResponse>(`${combinedEndpoint}`, body);

            return data;
        } catch (error) {
            throw error;
        }

    }

    const markCloseOne = async ({ endpoint, key }: {
        key: string | number,
        endpoint?: string,
    }): Promise<TFetchOneResponse> => {
        const combinedEndpoint = endpoint ? `${baseEndpoint}/${endpoint}` : baseEndpoint
        try {
            const { data } = await axios.post<TStoreOneResponse>(`${combinedEndpoint}/mark-closed/${key}`);
            return data;
        } catch (error) {
            throw error;
        }

    }
    return {
        fetchOne,
        fetchAll,
        createOne,
        markCloseOne
    };
};

export default useApi;