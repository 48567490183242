import { onMounted, onBeforeUnmount } from 'vue'

interface props {
    onScan: (code: string) => void;
}

const useScanner = ({ onScan }: props) => {
    const handleKeydownDesktop = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.shiftKey && event.key === 'L') {
            let code = prompt("Provide code to simulate scan:")
            code = code ? code : ' '

            onScan(code)
        }
    }

    onMounted(() => {
        if (window.plugins) {
            window.plugins.intentShim.registerBroadcastReceiver({
                filterActions: ['']
            }, function(intent) {
                const value = intent.extras['com.symbol.datawedge.data_string']

                onScan(value)
            });
        } else {
            window.addEventListener('keydown', handleKeydownDesktop)
        }
    })

    onBeforeUnmount(() => {
        if (window.plugins) {
            window.plugins.intentShim.unregisterBroadcastReceiver()
        } else {
            window.removeEventListener("keydown", handleKeydownDesktop);
        }
    })
}

export default useScanner