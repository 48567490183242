import { computed, onMounted, reactive, Ref, ref, unref } from "vue";
import { useToast } from "vue-toastification";
import { useRouter } from "vue-router";

import { TDeliveryDocumentType, TDocument } from "@/const/types/documents";
import { IScannedArticle } from "@/const/types/article";
import useDocumentApi from "@/composable/api/http/useDocumentApi";
import useActionsApi from "@/composable/api/http/useActionsApi";
import usePlaceApi from "@/composable/api/http/usePlaceApi";
import { tryCatchAsync } from "@/utils/tryCatchAsync";

interface ILoadingDocument {
    isLoading: boolean;
    data: TDocument | null
} 

const useCreateDeliveryDocument = (documentIdProxy: number | Ref<number>) => {
    const selectedType = ref<null | TDeliveryDocumentType>(null)
    const document = reactive<ILoadingDocument>({ isLoading: true, data: null })
    const place = reactive({ isLoading: false, data: '', activeAlert: false })
    const isLoadingCreate = ref(false)

    const deliveryTypeBlocked = computed(() => document.data?.output_type != null && document.data?.output_type.length > 0)
    const isViewLoading = computed(() => isLoadingCreate.value || document.isLoading || place.isLoading)

    const documentsApi = useDocumentApi()
    const { showPlace } = usePlaceApi()
    const actionsApi = useActionsApi()
    const toast = useToast()
    const router = useRouter()

    const loadDocumentInformation = async () => {
        const documentId = unref(documentIdProxy)

        try {
            const data = await documentsApi.showDocument(documentId, { with: ['products.combination', 'products.product', 'products.combination.product'] })
            document.data = data
            selectedType.value = document.data!.output_type as (TDeliveryDocumentType | null)
        } catch (error) {
            console.error(error)
        }
    }

    const acceptDelivery = async (scannedArticlesProxy: IScannedArticle[] | Ref<IScannedArticle[]>) => {
        const documentId = unref(documentIdProxy)
        const scannedArticles = unref(scannedArticlesProxy)

        const data = {
            documentId,
            documentType: selectedType.value,
            products: scannedArticles.map(article => ({ articleCode: article.code, quantity: article.quantity })),
            placeCode: place.data,
        }

        isLoadingCreate.value = true
        const [deliveryDocument, actionError] = await tryCatchAsync(() => actionsApi.acceptDelivery(data))
        isLoadingCreate.value = false

        if (actionError) {
            if (actionError.data && actionError.data.errors && actionError.data.errors.message) {
                toast.error(actionError.data.errors.message)
            } else if (actionError.data && actionError.data.message) {
                toast.error(actionError.data.message)
            } else {
                toast.error(actionError.statusText)
            }
            place.activeAlert = false
            return
        }

        toast.success(`Delivery accepted with document ${deliveryDocument.name}`)
        router.push({ name: 'dashboard' })
    }

    const endScanArticlesAction = () => {
        place.activeAlert = true
    }

    const scanPlaceSubmit = async (code: string, scannedArticlesProxy: IScannedArticle[] | Ref<IScannedArticle[]>) => {
        const scannedArticles = unref(scannedArticlesProxy)

        place.isLoading = true
        place.data = ""

        const [placeValue, placeError] = await tryCatchAsync(() => showPlace(code, { findKey: 'code', with: ['attributes', 'children'] }))

        if (placeError) {
            if (placeError.status == 404) {
                toast.error('Place not found')
            } else {
                toast.error(placeError.data.message ?? (placeError.statusText ?? 'api error'))
            }

            place.activeAlert = false
            place.isLoading = false

            return
        }

        if ((placeValue.children ?? []).length > 0) {
            toast.error('This place has children. Cannot be set as placement place.')

            place.activeAlert = false
            place.isLoading = false

            return
        }
        place.activeAlert = false
        place.isLoading = false
        if(placeValue.is_busy){
            toast.error('This place is busy')
            return;
        }
        toast.info(`Place ${placeValue.name} found. Accepting delivery.`)
        place.data = code
        acceptDelivery(scannedArticles)

        return
    }

    onMounted(async () => {
        document.isLoading = true
        await loadDocumentInformation()
        document.isLoading = false
    })

    return {
        document,
        place,
        selectedType,
        isLoading: isViewLoading,
        endScanArticlesAction,
        scanPlaceSubmit,
        acceptDelivery,
        deliveryTypeBlocked,
        loadDocumentInformation,
    }
}

export default useCreateDeliveryDocument