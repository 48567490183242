import { RouteRecordRaw } from "vue-router"
import { useToast } from "vue-toastification"
import useUserStore from "@/store/user"

import LoginView from "@/views/auth/login/LoginView.vue"

const introRoutes: RouteRecordRaw[] = [
    {
        name: "login",
        path: "/auth/login/:loginStage?",
        component: LoginView,
        beforeEnter(cur, prev, next) {
            if (cur.name !== 'login') return

            const userStore = useUserStore()

            if (userStore.state.user){
                const toast = useToast()

                console.error('you are logged')
                toast.error("Logged user can't enter authentication routes!")

                return
            }

            const isDomainSet = Boolean(localStorage.getItem('api-domain'))
            const param = cur.params.loginStage

            if (!isDomainSet && param == '1')
                return next({ name: 'login', params: { loginStage: 0 } })
            else if (isDomainSet && !param)
                return next({ name: 'login', params: { loginStage: 1 } })
            else if (isDomainSet && param == '0')
                return next({ name: 'login', params: { loginStage: 1 } })

            return next();
        },
    },
]

export default introRoutes