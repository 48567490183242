import { RouteRecordRaw } from "vue-router"

import SelectStocktakingDocumentsEntiresView from '@/views/logged/stocktaking/SelectStocktakingDocumentsEntiresView.vue'
import SelectStocktakingDocumentView from '@/views/logged/stocktaking/SelectStocktakingDocumentView.vue'
import StartStockTakingDocumentView from "@/views/logged/stocktaking/StartStockTakingDocumentView.vue"

const stocktakingRoutes: RouteRecordRaw[] = [
    {
        name: "stocktaking/select-articles",
        path: "stocktaking/select-articles",
        component: SelectStocktakingDocumentsEntiresView,
        meta: { requireAuth: true, }
    },
    {
        name: "stocktaking/show-document",
        path: "stocktaking/show-document/:id",
        component: SelectStocktakingDocumentView,
        meta: { requireAuth: true, }
    },
    {
        name: "stocktaking/register-placements",
        path: "stocktaking/register-placements/:id",
        component: StartStockTakingDocumentView,
        meta: { requireAuth: true, }
    },
]

export default stocktakingRoutes