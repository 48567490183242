import { Ref, ref, unref } from "vue";

const useChangeArticleQuantityAlert = (
    _articles: Ref<{ code: string; }[]> | { code: string; }[],
    updateQuantityHandler: (code: string, quantity: number) => void
) => {
    const isOpenAlert = ref(false);
    const articleCode = ref("");

    const openChangeQuantityAlert = (article) => {
        articleCode.value = article.code
        isOpenAlert.value = true
    };
    const closeChangeQuantityAlert = () => isOpenAlert.value = false;

    const alertButton = [{
        text: 'Change',
        role: 'confirm',
        handler: async (data: { quantity: number; }) => {
            const articles = unref(_articles)

            const scannedArticleIndex = articles.findIndex((article) => article.code == articleCode.value)
            const scannedArticle = articles[scannedArticleIndex]

            if (scannedArticleIndex < 0 || !(articles[scannedArticleIndex] ?? null)) {
                return
            }

            if (!data.quantity || data.quantity < 1) return

            updateQuantityHandler(scannedArticle.code, Number(data.quantity))
        },
    }];

    const alertInputs = [
        {
            name: 'quantity',
            type: 'number',
            placeholder: 'New quantity',
            min: 1,
        },
    ]

    return {
        alertButton,
        alertInputs,
        isOpenAlert,
        openChangeQuantityAlert,
        closeChangeQuantityAlert,
    }
}

export default useChangeArticleQuantityAlert;