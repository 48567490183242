import { Ref, ref, unref } from "vue";
import socketManager from '@/utils/libs/socketManager';
import { TPlace } from "@/const/types/place";
import { TProduct } from "@/const/types/product";
import { TCombination } from "@/const/types/combination";

const useArticleManagement = (
    documentId: number,
) => {
    // Holds the current state of articles within the selected place.
    const articles = ref([]);

    /**
     * Handles updates to the quantities of articles based on data received,
     * typically from a WebSocket event. It verifies whether the updates belong to the
     * current document and the selected place before applying them to the state.
     * This function is crucial for maintaining the consistency of displayed articles
     * in response to real-time changes.
     * 
     * @param selectedPlace - A reference to the currently selected place.
     * @param data - The structure containing the document ID, place ID, 
     *                                      and an array of updated placements.
     */
    const updateQuantityHandler = (selectedPlace: Ref<TPlace>, { data }: { data: { document_id: number; place_id: number; placements: { article: TProduct | TCombination, quantity: number }[] } }) => {
        if (!selectedPlace || !selectedPlace.value) return

        const currentPlaceId = unref(selectedPlace).id

        if (
            data.document_id != documentId
            || data.place_id != currentPlaceId
        ) return;

        articles.value = data.placements.map((placement: { article: TProduct | TCombination, quantity: number }) => ({
            ...placement.article,
            quantity: placement.quantity,
        }))
    };

    /**
     * Resets the articles array to an empty array.
     * This function can be used to clear the current list of articles.
     */
    const clearArticles = () => {
        articles.value = [];
    };

    return {
        articles,
        updateQuantityHandler,
        clearArticles,
    }
};

export default useArticleManagement;
