import { onMounted, ref, Ref, watch, toRefs, unref } from "vue"
import { debounce } from 'lodash'

import useDocumentsStore from '@/store/documents';
import { TDocument } from '@/const/types/documents';
import { InfiniteScrollCustomEvent } from "@ionic/vue";

interface IUseDocumentsLoaderParams {
    filterParams: Record<string, unknown> | Ref<Record<string, unknown>>;
    filterTypes: { type: string; key: string; }[];
    onFoundExactOneDocument?: (document: TDocument) => void;
    dontLoadOnMounted?: boolean;
}

/**
 * Hook to load and manage documents with infinity/scrollable loader.
 * 
 * @param params.filterParams - Parameters used for filtering documents.
 * @param params.filterTypes - Key-value pair of filter type objects.
 * @param params.onFoundExactOneDocument - Fires when only one document found.
 */
const useDocumentsLoader = <FoundType = ''>({
    filterParams,
    filterTypes,
    onFoundExactOneDocument,
    dontLoadOnMounted,
}: IUseDocumentsLoaderParams) => {
    const searchCode = ref("")
    const foundType: Ref<null | FoundType> = ref(null)
    const isLoading = ref(false)

    const documentsStore = useDocumentsStore();

    const { documents, total: maxDocuments } = toRefs(documentsStore.state)

    /**
     * Function to load more documents, used in an infinite scroll load more event.
     * @param ev - Event from the infinite scroll component.
     */
    const loadMore = async (ev: InfiniteScrollCustomEvent) => {
        isLoading.value = true
        await documentsStore.actions.fetchPage({ params: unref(filterParams) })
        isLoading.value = false

        ev.target.complete()
    }

    /**
     * Function to reset the search and fetch the first page of documents.
     * @function
     */
    const resetSearch = () => {
        documentsStore.actions.fetchPage({ page: 1, params: unref(filterParams) });
        foundType.value = null;
    };

    /**
     * Private fn to fetch documents based on a search value and filter type.
     * @param searchValue - The value to search for.
     * @param filterType - The type of filter to apply.
     */
    const fetchDocuments = async (searchValue: string, filterType: string): Promise<boolean> => {
        const unrefFilterParams = unref(filterParams) 
        const params = {
            ...unrefFilterParams,
            filters: {
                [filterType]: searchValue,
                ...(typeof unrefFilterParams.filters == 'object' ? unrefFilterParams.filters : {}),
            },
        };

        isLoading.value = true
        await documentsStore.actions.fetchPage({ page: 1, params });
        isLoading.value = false

        if (documents.value.length > 1) {
            return true
        }

        if (documents.value.length == 1) {
            onFoundExactOneDocument(documents.value[0])
        }

        return false;
    };

    /**
     * Function to search for documents based on a search value.
     * @param searchValue - The value to search for.
     */
    const searchDocuments = async (searchValue: string) => {
        if (!searchValue) {
            resetSearch()
            return;
        }

        for (const { type, key } of filterTypes) {
            const documentFound = await fetchDocuments(searchValue, key);

            if (documentFound) {
                foundType.value = ['combination', 'document', 'product'].includes(type)
                    ? (type as FoundType)
                    : null;
                break;
            }
        }
    };

    watch(searchCode, debounce(async () => {
        searchDocuments(searchCode.value)
    }, 500))

    onMounted(() => {
        if (dontLoadOnMounted === true) return

        documentsStore.actions.fetchPage({ page: 1, params: unref(filterParams) })
    })

    return {
        loadMore,
        documents,
        maxDocuments,
        resetSearch,
        isLoading,

        foundType,
        searchCode,
    }
}

export default useDocumentsLoader