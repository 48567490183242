import { computed, ref } from 'vue'
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';

import menu, { TMenuItem } from './../../../router/menu';

const useAppTabs = () => {
    const router = useRouter()
    const toast = useToast()

    const menuItems = computed(() => {  
        const items: {
            [key: string]: TMenuItem[]
        } = {}

        menu.forEach(item => {
            if (Array.isArray(items[item.groupName])) {
                items[item.groupName].push(item)
            } else {
                items[item.groupName] = [ item ]
            }
        })

        return items 
    });
    const menuItemsGroups = computed(() => Object.keys(menuItems.value))

    const isModalMenuOpen = ref(false)
    const openModalMenu = () => isModalMenuOpen.value = true
    const closeModalMenu = () => isModalMenuOpen.value = false
    const goBackRoute = () => {
        if (window.history.state.back) {
            router.back()
        } else {
            toast.error('There is no route back.')
        }
    }

    return {
        menu: {
            menuItems,
            menuItemsGroups,
        },
        isModalMenuOpen,
        openModalMenu,
        closeModalMenu,
        goBackRoute,
    }
}

export default useAppTabs;