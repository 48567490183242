
import { defineComponent } from 'vue';
import useLogin, { LOGIN_STAGES } from './useLogin'
import {
    IonPage,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
} from '@ionic/vue';

import LoginFormDomain from '@components/login/LoginFormDomain.vue';
import LoginFormUser from '@components/login/LoginFormUser.vue';

export default defineComponent({
    name: 'LoginView',
    components: { IonHeader, IonToolbar, IonTitle, IonContent, IonPage },
    setup() {
        const { loginStage, onSubmitHandlers, formsDetails } = useLogin()

        const forms = {
            [LOGIN_STAGES.PROVIDE_DOMAIN]: LoginFormDomain,
            [LOGIN_STAGES.AUTHORIZE_USER]: LoginFormUser,
        }

        return {
            forms,
            loginStage,
            formsDetails,
            onSubmitHandlers,
        }
    },
});
