import { useRoute } from 'vue-router';
import { computed, onMounted, ref } from 'vue';
import { TArticleWithPackage } from '@/const/types/article';

/* Components */
import ScannedPackageDetailsView from './../scanned/PackageDetailsView.vue'
import ScannedProductDetailsView from './../scanned/ProductDetailsView.vue'
import ScannedCombinationDetailsView from './../scanned/CombinationDetailsView.vue'
import ScannedNotFoundArticle from './../scanned/NotFoundArticle.vue'
import { lookForQueue } from './lookForQueue';
import { tryCatchAsync } from '@/utils/tryCatchAsync';

const useScanArticleResult = () => {
    const route = useRoute();

    const articleData = ref<TArticleWithPackage>({ data: null, type: null });

    const errorMsg = ref('');
    const isLoading = ref(false);

    const articleCode = computed(() => (route.params.code as string) ?? '');
    const isCorrectLoaded = computed(() => !isLoading.value && !errorMsg.value && articleData.value)

    const resultViewComponent = computed(() => {
        switch (true) {
            case (isCorrectLoaded.value && articleData.value.type == 'Package'):
                return ScannedPackageDetailsView
            case (isCorrectLoaded.value && articleData.value.type == 'Combination'):
                return ScannedCombinationDetailsView
            case (isCorrectLoaded.value && articleData.value.type == 'Product'):
                return ScannedProductDetailsView
            case (!isLoading.value):
                return ScannedNotFoundArticle
            default:
                return null
        }
    })

    const findArticle = async () => {
        isLoading.value = true;

        for await (const category of lookForQueue) {
            const [item, error] = await tryCatchAsync(() => category.findHandler(articleCode.value))

            if (error && error.status != 404) {
                isLoading.value = false;
                const errorMessage = error.data.message ?? 'Error occures at loading product info.';
                errorMsg.value = errorMessage;
            }

            if (item) {
                articleData.value.type = category.type;
                articleData.value.data = category.castData(item);

                isLoading.value = false;
                break;
            }

        }

        isLoading.value = false;
    }

    onMounted(() => { findArticle() });

    return {
        articleData,
        articleCode,
        resultViewComponent,
        form: {
            isLoading,
            errorMsg,
        },
    }
}

export default useScanArticleResult