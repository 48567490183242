<template>
    <section v-once>
        <h6> The procedure for stocktaking is as follows: </h6>
        <ol>
            <li>Scan the location you want to note down quantity of articles.</li>
            <li>Take each article individually and scan it once.</li>
            <li>The articles displayed below represent the number of scanned articles in this document by users.</li>
            <li>You can change locations by scanning another code.</li>
            <li>If you have finished scanning all desired items, remember to confirm the document with the "OK" button. Otherwise, it will not be included in the final count.</li>
        </ol>
        <p><strong>Note:</strong></p>
        <p>If you make an error, you can click the "CLEAR PLACEMENTS" button. It will reset the states in this document only for the scanned location.</p>
    </section>
</template>
