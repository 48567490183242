import { useRouter } from 'vue-router';
import { TDocument } from '@/const/types/documents';
import useDocumentsLoader from '@/composable/useDocumentsLoader';

const filterParams = {
  calculateDaysUntilDelivery: true,
  filters: { type: 'ZD', is_active: 1 },
  sort: { days_until_delivery: 'asc' },
} as const;

const filterTypes = [
    { type: 'document', key: 'code' },
    { type: 'combination', key: 'products.combination.code' },
    { type: 'combination', key: 'products.combination.packages.code' },
    { type: 'product', key: 'products.product.code' },
    { type: 'product', key: 'products.product.packages.code' },
];

const useGetEntryDeliveryDocuments = () => {
  const router = useRouter();

  const onFoundExactOneDocument = (document: TDocument) => {
    if (!document) {
      return;
    }

    return router.push({
      name: 'delivery/create-delivery-document',
      params: { id: document.id },
    });
  };

  const {
    loadMore,
    documents,
    maxDocuments,

    foundType,
    searchCode,
  } = useDocumentsLoader<'combination' | 'document' | 'product'>({
    filterParams,
    filterTypes,
    onFoundExactOneDocument,
  });

  return {
    loadMore,
    documents,
    maxDocuments,

    foundType,
    searchCode,
  };
};

export default useGetEntryDeliveryDocuments;
