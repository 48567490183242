import { IScannedArticle } from "@/const/types/article";
import { Ref, ref, unref } from "vue";

const useDeleteSingleArticle = (
    _articles: Ref<{ code: string; }[]> | { code: string; }[],
    deleteArticleHandler: (code: string) => void
) => {
    const isOpenAlert = ref(false);
    const articleCode = ref("");

    const openDeleteSingleArticleAlert = (article: IScannedArticle) => {
        articleCode.value = article.code
        isOpenAlert.value = true
    };
    const closeDeleteSingleArticleAlert = () => isOpenAlert.value = false;

    const alertButton = [{
        text: 'Remove',
        role: 'confirm',
        handler: async () => {
            const articles = unref(_articles)
            const scannedArticleIndex = articles.findIndex((article) => article.code == articleCode.value)

            if (scannedArticleIndex < 0 || !(articles[scannedArticleIndex] ?? null)) {
                return
            }

            const scannedArticle = articles[scannedArticleIndex]

            deleteArticleHandler(scannedArticle.code)
        },
    }];

    return {
        alertButton,
        isOpenAlert,
        openDeleteSingleArticleAlert,
        closeDeleteSingleArticleAlert,
    }
}

export default useDeleteSingleArticle;