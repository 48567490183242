import { BasicShowRequestParams } from "@/const/types/endpoint";
import useApi from "./useApi";
import { TProduct, TProductRelations } from "@/const/types/product";

interface IShowProductParams extends BasicShowRequestParams {
    with?: TProductRelations[];
}

const useProductApi = () => {

    const { fetchOne } = useApi<TProduct>('products');

    /**
     * GET /index/id - returns all specific information about product based on provided params.
     * 
     * @doc https://unlimitech.stoplight.io/docs/wms/branches/main/63832f6be6989-show-one-product
     */
    const showProduct = async (key: string | number, params: IShowProductParams): Promise<TProduct | null> => {
        try {
            const { data: product } = await fetchOne<IShowProductParams>({ key, params })

            return product;
        } catch (error) {
            throw error
        }
    };

    return {
        showProduct,
    }
}

export default useProductApi