import useArticleManagement from '@/composable/stocktaking/useArticleManagement';
import usePlaceSelection from '@/composable/stocktaking/usePlaceSelection';
import useStocktakingSocketEvents from '@/composable/stocktaking/useStocktakingSocketEvents';
import { onMounted, onUnmounted } from 'vue';

/**
 * Coordinates the document creation process for stocktaking, integrating place selection,
 * article management, and WebSocket communications for real-time updates and interactions.
 *
 * @param {number} documentId - The unique identifier for the stocktaking document.
 * @returns An object containing methods for handling scans, place and article management,
 *          and references to articles and the selected place.
 */
const useCreateCensusSheetDocument = (documentId: number) => {
  // Manage articles within the selected place
  const { articles, updateQuantityHandler, clearArticles } = useArticleManagement(documentId)

  // Handle place selection and clear articles upon selection
  const { selectPlace, selectedPlace } = usePlaceSelection((placeId) => {
    clearArticles()
    getPlacePlacementsStatus({ placeId })
  })

  // Setup and manage WebSocket communications
  const { 
    increaseArticlePlacementQuantity, getPlacePlacementsStatus,
    clearPlacementsForPlace, setArticlePlacementQuantity,
    setupSocketListeners, removeSocketListeners,
  } = useStocktakingSocketEvents({
    documentId,
    onErrorOccurs: {
      onPlaceError() {
        selectedPlace.value = null
      },
    },
    onUpdatePlacementQuantity: (data) => updateQuantityHandler(selectedPlace, data),
  })

  onMounted(() => {
    if (Number.isNaN(Number(documentId))) {
      throw new Error('Provided documentId prop is not a number')
    }

    setupSocketListeners()
  })

  onUnmounted(() => {
    removeSocketListeners()
  })

  const handleScan = async (code: string) => {
    const foundNewPlace = await selectPlace(code);

    if (foundNewPlace || !selectedPlace.value) return;

    increaseArticlePlacementQuantity({
      articleCode: code,
      placeId: selectedPlace.value.id
    })
  };

  /**
   * Clears all article placements for the currently selected place. This action resets 
   * the article quantities to their initial state for the selected place. The function 
   * will not execute if no place is currently selected.
   */
  const clearPlacements = () => {
    // TODO: alert z błędem
    if (!selectedPlace.value.id) return

    clearPlacementsForPlace({ placeId: selectedPlace.value.id })
  };

  /**
   * Changes the quantity of a specific article within the currently selected place.
   * This is typically used to update the stock count of an article based on user input or scan data.
   * 
   * @param {string} code - The unique code of the article whose quantity is being updated.
   * @param {number} quantity - The new quantity to set for the article.
   * The function will not execute if no place is currently selected.
   */
  const changeArticleQuantity = (code: string, quantity: number) => {
    if (!selectedPlace.value.id) return

    setArticlePlacementQuantity({ articleCode: code, quantity, placeId: selectedPlace.value.id })
  }

  /**
   * Deletes the specified article's quantity or resets it to zero for the currently selected place.
   * This can be used to remove an article from the list of scanned items in a stocktaking process.
   * 
   * @param {string} code - The unique code of the article to delete or reset.
   * The function will not execute if no place is currently selected.
   */
  const deleteArticleQuantity = (code: string) => {
    if (!selectedPlace.value.id) return

    clearPlacementsForPlace({ placeId: selectedPlace.value.id, articleCode: code })
  }

  return {
    handleScan,
    selectPlace,
    clearPlacements,

    changeArticleQuantity,
    deleteArticleQuantity,

    articles,
    selectedPlace,
  };
};

export default useCreateCensusSheetDocument;
