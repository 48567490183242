/**
 * Wraps an async function in a try-catch block and returns the result or the error in an array.
 * 
 * @template TResult The type of the result that the async function returns.
 * @template TError The type of the error that the async function may throw.
 * @param {() => Promise<TResult>} fn The async function to be wrapped in a try-catch block.
 * @returns {Promise<[TResult | null, TError | null]>} A Promise that resolves to an array containing either the result or the error.
 * 
 * Usage:
 * 
 * const asyncFunction = async () => {
 *   // Some async operation
 * };
 *
 * const [result, error] = await tryCatchAsync(asyncFunction);
 *
 * if (error) {
 *   // Handle error
 * } else {
 *   // Handle result
 * }
 */
export async function tryCatchAsync<TResult = any, TError = any>(
  fn: () => Promise<TResult>
): Promise<[TResult | null, TError | null]> {
  try {
    const result = await fn();
    return [result, null];
  } catch (error) {
    return [null, error as TError];
  }
}