import axios, { AxiosError } from "axios"
import { useToast } from "vue-toastification";
import useUserStore from "@/store/user";

const toast = useToast();

export type TRespError = Error | AxiosError

export const axiosIns = axios.create({
	responseType: 'json',
	timeout: 8 * 1000,
	headers: {
		Accept: 'application/json',
	},
})

axiosIns.interceptors.request.use(
  config => {
    const token = localStorage.getItem('wms-token') ? JSON.parse(localStorage.getItem('wms-token')) : null;

    if (token) {
		config.headers.Authorization = `Bearer ${token}`;
    }

	const apiDomain = localStorage.getItem('api-domain')

	if (apiDomain) {
		config.baseURL = apiDomain
	}

    return config;
  },
  error => Promise.reject(error)
);

function createAxiosResponseInterceptor() {
	const interceptor = axiosIns.interceptors.response.use(function (response) {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;

	}, function (error: AxiosError) {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error

		// timeout
		if (error.code === 'ECONNABORTED') {
			toast.error('Połączenie z internetem jest wolne. Spróbuj ponownie')
			return Promise.reject(error)
		}

		if (!error.response || !error.response.status)
			return Promise.reject(error)

		if (error.response.status === 401) {
			const apiDomain = localStorage.getItem('api-domain')
			const token = localStorage.getItem('wms-token') ? JSON.parse(localStorage.getItem('wms-token')) : null;

			if (!apiDomain || !token) {
				const userStore = useUserStore();
				userStore.actions.logout(false)

				return Promise.reject(error)
			}

			axios.interceptors.response.eject(interceptor);

			return axios
                .get(`${apiDomain}/auth/refresh`, { headers: { Authorization: `Bearer ${token}` } })
                .then((response) => {
					const newToken = response.data.data.token as string

                    error.response.config.headers["Authorization"] =
						`Bearer ${newToken}`

					const userStore = useUserStore()
					userStore.actions.updateUserDataFromLoginRequest(response.data)

                    // Retry the initial call, but with the updated token in the headers. 
                    // Resolves the promise if successful
                    return axios(error.response.config);
                })
                .catch((error2) => {
                    // Retry failed, clean up and reject the promise
					const userStore = useUserStore();
					userStore.actions.logout(false)
					toast.info('Sesja wygasła.')

					return Promise.reject(error2)
                })
                .finally(createAxiosResponseInterceptor); // Re-attach the interceptor by running 
		}

		return Promise.reject(error.response);
	});
}

createAxiosResponseInterceptor()

export default axiosIns