import usePlaceApi from "@/composable/api/http/usePlaceApi";
import useAggregationPlacementModal from "@/composable/useAggregationPlacementModal";
import useDocumentsLoader from "@/composable/useDocumentsLoader";
import { TAttribute } from "@/const/types/attributes";
import { TDocument } from '@/const/types/documents';
import { TPlace } from "@/const/types/place";
import { Ref, ref, unref } from "vue";
import { useRouter } from "vue-router";

const filterParams = {
    calculateDaysUntilDelivery: true,
    filters: { type: "FI", is_active: 1 },
    sort: { days_until_delivery: 'asc' },
    with: ['attributes','aggregatedPlacements','referencedDocument']
} as const;

const filterTypes = [
    { type: 'document', key: 'code' },
];

const useGetEntryStockTakingDocuments = () => {
    const router = useRouter();
    const { showPlace } = usePlaceApi()
    const isAllWarehouse = ref(false);
    const places = ref<TPlace[]>([]);

    const onFoundExactOneDocument = (document: TDocument) => {
        return router.push({name : 'stocktaking/show-document', params: { id: document.id }})
    }

    const handleShowPlaces = async (_documentAttributes: TAttribute[] | Ref<TAttribute[]>) => {
        const documentAttributes = unref(_documentAttributes)
        const attributesArrayNameValueFormat = documentAttributes.map(attribute => [attribute.name, attribute.value])
        const attributesNameValue = Object.fromEntries(attributesArrayNameValueFormat) 

        if (
            attributesNameValue['generated_for_all_warehouse']
            && attributesNameValue['generated_for_all_warehouse'] == '1'
        ) {
            isAllWarehouse.value = true
            return
        }

        if (!attributesNameValue['generated_for_places']) {
            places.value = [];
            return
        }

        const placeIds = attributesNameValue['generated_for_places'].split(';;').map(Number)

        // TODO: refactor to get all !!!!
        const placeDataPromises = placeIds.map(placeId => 
            showPlace(placeId, { findKey: 'id', with: ['attributes'] })
        );

        const placeData = await Promise.all(placeDataPromises);

        places.value = placeData;
        isAllWarehouse.value = false
    };
    
    const {
        loadMore,
        documents,
        maxDocuments,

        foundType,
        searchCode,
    } = useDocumentsLoader<'document'>({
        filterParams,
        filterTypes,
        onFoundExactOneDocument,
    });

    return {
        loadMore,
        handleShowPlaces,
        documents,
        maxDocuments,

        places,
        isAllWarehouse,

        foundType,
        searchCode,
    }
}

export default useGetEntryStockTakingDocuments