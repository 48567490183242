import { Ref, unref } from "vue"
import { TPlacementsRelatedForZDCompact, TDocument } from "@/const/types/documents"

const useDocumentArticles = () => {
    const getArticles = (documentProxy: TDocument | Ref<TDocument>): TPlacementsRelatedForZDCompact[] => {
        const document = unref(documentProxy)

        if (!document) return []

        const articles = []

        if (document.placementsRelatedForZD) {
            articles.push(...document.placementsRelatedForZD)
        } else {
            articles.push(...document.products)
        }

        const documentProducts: TPlacementsRelatedForZDCompact[] = articles
            .filter(article => article.product_id)
            .map(product => ({
                document_product_id: product.document_product_id,
                type: 'product',
                quantity: product.quantity,
                declared_quantity: product.declared_quantity ?? -1,
                scanned_quantity: product.scanned_quantity ?? -1,
                id: product.product!.id,
                code: product.product!.code,
                name: product.product!.name,
                imageUrl: product.product!.image_url,
            }))

        const documentCombinations: TPlacementsRelatedForZDCompact[] = articles
            .filter(article => article.combination_id)
            .map(product => ({
                document_product_id: product.document_product_id,
                type: 'combination',
                quantity: product.quantity,
                declared_quantity: product.declared_quantity ?? -1,
                scanned_quantity: product.scanned_quantity ?? -1,
                id: product.combination!.id,
                code: product.combination!.code,
                name: `${product.combination?.product!.name} | ${product.combination!.name}`,
                imageUrl: product.combination!.image_url,
            }))

        return [...(documentProducts ?? []), ...(documentCombinations ?? [])]
    }

    return {
        getArticles,
    }
}

export default useDocumentArticles