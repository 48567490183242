
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent, onBeforeMount } from 'vue';
import { useRoute } from 'vue-router';
import useUserStore from '@/store/user';
import socketManager from '@/utils/libs/socketManager'
import useMissingNetworkAlert from '@/composable/network/useMissingNetworkAlert'

export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet
  },
  setup() {
    useMissingNetworkAlert()
    const route = useRoute()

    onBeforeMount(() => {
      const userStore = useUserStore()
      userStore.actions.fetchActiveUser()

      try {
        socketManager.initializeManager({})
      } catch (error) {
        console.error(error)
      }
    })

    return {
      route
    }
  }
});
