import useCombinationApi from "@/composable/api/http/useCombinationApi";
import usePackageApi from "@/composable/api/http/usePackageApi";
import useProductApi from "@/composable/api/http/useProductApi";
import { TCombination, TCombinationRelations } from "@/const/types/combination"
import { TPackage, TPackageRelations } from "@/const/types/package";
import { TProduct, TProductRelations } from "@/const/types/product";

type TLookForQueueItem = {
    findHandler: (code: string | number) => Promise<TPackage | TCombination | TProduct>,
    type: 'Product' | 'Combination' | 'Package';
    castData: (data: TPackage | TProduct | TCombination) => TPackage | TProduct | TCombination;
}

const packageWith: TPackageRelations[] = ['product', 'combination.product']
const combinationWith: TCombinationRelations[] = ['product', 'attributes', 'product.attributes', 'product.combinations', 'product.combinations.attributes', 'aggregatedPlacements.place', 'aggregatedPlacements.place.attributes']
const productWith: TProductRelations[] = ['combinations', 'attributes', 'combinations.attributes', 'aggregatedPlacements.place', 'aggregatedPlacements.place.attributes']

export const lookForQueue: TLookForQueueItem[] = [
    {
        type: 'Package',
        castData: (data: TPackage | TProduct | TCombination) => data as TPackage,
        async findHandler(code) {return await usePackageApi().showPackage(code, { findKey: 'code', with: packageWith }) },
    },
    {
        type: 'Combination',
        castData: (data: TPackage | TProduct | TCombination) => data as TCombination,
        async findHandler(code) {return await useCombinationApi().showCombination(code, { findKey: 'code', with: combinationWith}) },
    },
    {
        type: 'Product',
        castData: (data: TPackage | TProduct | TCombination) => data as TProduct,
        async findHandler(code) {return await useProductApi().showProduct(code, { findKey: 'code', with: productWith}) },
    }
]