import useActionsApi from "@/composable/api/http/useActionsApi";
import { TDocument } from "@/const/types/documents";
import { IAggregatedPlacementCompact } from "@/const/types/placement";
import { tryCatchAsync } from '@/utils/tryCatchAsync';
import useMovePlacementsActionStore from "@/store/movePlacementsAction";
import { storeToRefs } from "pinia";
import { useRouter } from "vue-router";
import { useToast } from "vue-toastification";

interface IArticlesOnPlace {
    [key: string]: IAggregatedPlacementCompact[];
}

const useMovePlacementsUtilities = () => {
    const toast = useToast();
    const router = useRouter();
    const movePlacementsActionStore = useMovePlacementsActionStore();
    const { verifyPlacement } = useActionsApi();
    const { putArticlesList, selectedArticles, selectedPlace } = storeToRefs(movePlacementsActionStore);


    /**
     * Redirects to the "move-placements/select-articles" route if no articles are selected.
     * @param selectedArticles - The selected articles object.
     */
    const redirectToSelectArticlesIfNoSelection = (selectedArticles: IArticlesOnPlace) => {
        const hasAnythingSelected = Object.values(selectedArticles).flat().length > 0;
        if (!hasAnythingSelected) {
            router.push({ name: "move-placements/select-articles" });
        }
    };


    /**
     * Formats articles data for the API call.
     * @param articlesData - The articles data object.
     * @param multiplier - The multiplier to apply to the article's quantity.
     * @returns An array of formatted articles.
     */
    const formatArticles = (articlesData: IArticlesOnPlace, multiplier: number) => {
        const formattedArticles = [];
        for (const [placeCode, articles] of Object.entries(articlesData)) {
            formattedArticles.push(
                ...articles.map((article) => ({
                    placeCode,
                    articleCode: article.code,
                    quantity: article.quantity * multiplier,
                }))
            );
        }
        return formattedArticles;
    };


    /**
     * Formats the products data for the API call.
     * @returns An array of formatted products data.
     */
    const formatProductsApiData = () => {
        const takeArticlesFormatted = formatArticles(selectedArticles.value, -1);
        const putArticlesFormatted = formatArticles(putArticlesList.value, 1);
        return [...takeArticlesFormatted, ...putArticlesFormatted];
    };


    /**
     * Handles the successful move placement action by displaying a toast and redirecting to the dashboard.
     * @param zmDocument - The created document from the move placement action.
     */
    const handleSuccessfulMove = (zmDocument: TDocument) => {
        if (zmDocument) {
            toast.info(`Successfully moved placements. ${zmDocument.name} was created.`);
            router.push({ name: "dashboard" });
        }
    };

    /**
     * Resets the movePlacementsActionStore to its initial state.
     */
    const resetMovePlacementsActionStore = () => {
        movePlacementsActionStore.setSelectedPlace(null);
        putArticlesList.value = {};
        selectedArticles.value = {};
    };


    /**
     * Finds the index of the placement by the given code in the selectedArticlesCombinedPlace array.
     * @param code - The code of the article to find.
     * @returns  The index of the article in the array or -1 if not found.
     */
    const findPlacementIndexByCode = (code: string) => movePlacementsActionStore.selectedArticlesCombinedPlace().findIndex((article) => article.code == code);


    const findRemaningQuantityOfArticle = (code: string): number => {
        const article = (movePlacementsActionStore.getRemainingSelectedCombinedPlace() ?? []).find((article) => article.code == code);

        if (!article) return 0

        return article.quantity ?? 0
    }


    /**
     * Fetches the placement details for the given code.
     * @param code - The code of the article to fetch details for.
     * @returns A promise resolving to the placement details or null if an error occurred.
     */
    const fetchPlacementDetails = async (code: string) => {
        const [placementDetails, placementDetailsError] = await tryCatchAsync(() =>
            verifyPlacement({ articleCode: code, placeCode: selectedPlace.value.code }),
        );

        return placementDetailsError ? null : placementDetails
    }


    /**
     * Finds a placement in the selected place by the given code.
     * @param code - The code of the product or combination to find.
     * @returns The found placement or undefined if not found.
     */
    const findPlacement = (code: string) => selectedPlace.value.aggregatedPlacements.find(
        (p) => (p.product?.code ?? p.combination.code) === code,
    );


    return {
        formatArticles,
        findPlacement,
        handleSuccessfulMove,
        formatProductsApiData,
        fetchPlacementDetails,
        findPlacementIndexByCode,
        findRemaningQuantityOfArticle,
        resetMovePlacementsActionStore,
        redirectToSelectArticlesIfNoSelection,
    };
};

export default useMovePlacementsUtilities