import { RouteRecordRaw } from "vue-router"

import ScanArticleProvideCodeView from "@/views/logged/scan/ScanArticleProvideCodeView.vue"
import ScanArticleResultView from "@/views/logged/scan/ScanArticleResultView.vue"

import ScanPlaceProvideCodeView from "@/views/logged/scan/ScanPlaceProvideCodeView.vue"
import ScanPlaceResultView from "@/views/logged/scan/ScanPlaceResultView.vue"

const scanRoutes: RouteRecordRaw[] = [
    
    // Article
    {
        name: "scan/article-find",
        path: "scan/article-find",
        component: ScanArticleProvideCodeView,
        meta: { requireAuth: true, }
    },
    {
        name: "scan/article-result",
        path: "scan/article-result/:code",
        component: ScanArticleResultView,
        meta: { requireAuth: true, }
    },

    // Place
    {
        name: "scan/place-find",
        path: "scan/place-find",
        component: ScanPlaceProvideCodeView,
        meta: { requireAuth: true, }
    },
    {
        name: "scan/place-result",
        path: "scan/place-result/:code",
        component: ScanPlaceResultView,
        meta: { requireAuth: true, }
    },
]

export default scanRoutes