import { TSocketNamespace } from '@/const/types/socket';
import { Manager, ManagerOptions, Socket } from 'socket.io-client'
import { ref } from 'vue'

interface ISocketManagerInitializationParams {
    domain?: string;
}

const managerOptions: Partial<ManagerOptions> = {
    reconnectionDelayMax: 10_000,
    reconnectionAttempts: 10,
    randomizationFactor: 0.5,
    timeout: 20_000,
} as const

const useSocketManager = () => {
    const sockets = ref<Map<TSocketNamespace, Socket>>(new Map())
    let socketManager: Manager = null

    const leaveAllErrorsListeners = () => {
        const connections = Array.from(sockets.value.values())

        connections.forEach(socket => {
            socket.emit('errors/listen_errors:leave')
        })
    }

    // Becouse its singleton export when vue isn't full load
    window.addEventListener('beforeunload', leaveAllErrorsListeners)

    /**
     * Retrieves the authorization token from local storage.
     * 
     * @returns The token object or null if not found.
     */
    const getAuthToken = () => {
        const token = localStorage.getItem('wms-token');
        return token ? JSON.parse(token) : null;
    }

    /**
     * Constructs the socket configuration object.
     * 
     * @returns The socket configuration object.
     */
    const getSocketConfig = () => {
        const token = getAuthToken()
        if (!token) throw new Error('Cannot connect to api, authorization token is missing')

        return {
            auth: { token: `Bearer ${token}` }
        } as const;
    }

    /**
     * Initializes the socket manager with the specified options.
     * 
     * @param params - The initialization parameters.
     */
    const initializeManager = ({ domain }: ISocketManagerInitializationParams) => {
        let apiDomain = localStorage.getItem('api-domain') ?? ''

        if (!domain && !apiDomain) throw new Error('Domain not provided during initializing socket manager.')

        socketManager = new Manager(domain ?? apiDomain, managerOptions)
        sockets.value = new Map()
    }

    /**
     * Establishes a socket connection for the specified namespace.
     * 
     * @param namespace - The socket namespace.
     * @returns The socket object.
     */
    const establishSocketConnection = (namespace: TSocketNamespace): Socket => {
        if (!socketManager) throw new Error('Socket manager is not initialized.')

        const socket = socketManager.socket(namespace, getSocketConfig())

        socket.connect()

        sockets.value.set(namespace, socket)

        socket.on('connect', () => {
            socket.emit('errors/listen_errors:join')
        })

        return socket
    }

    /**
     * Retrieves a socket connection for the specified namespace, or creates a new one if it does not exist.
     * 
     * @param namespace - The socket namespace.
     * @returns The socket object.
     */
    const getSocket = (namespace: TSocketNamespace = '/') => {
        if (sockets.value.has(namespace)) {
            return sockets.value.get(namespace)
        }

        return establishSocketConnection(namespace)
    }

    return {
        initializeManager,
        getSocket,
    }
}

// Singleton export
export default useSocketManager()
