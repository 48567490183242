import { computed, onBeforeMount, watch, } from "vue";
import { TDocument } from "@/const/types/documents";
import { storeToRefs } from "pinia";
import useActionsApi from "@/composable/api/http/useActionsApi";
import useSelectArticlesBase from "./useSelectArticlesBase";
import useMovePlacementsUtilities from "./useMovePlacementsUtilities";
import useMovePlacementsActionStore from "@/store/movePlacementsAction";
import usePackageApi from "@/composable/api/http/usePackageApi";

const useSelectArticlesToPut = () => {
    const movePlacementsActionStore = useMovePlacementsActionStore();
    const { movePlacement } = useActionsApi();
    const { showPackage } = usePackageApi()
    const { onScan, isLoading } = useSelectArticlesBase({
        onArticleScan: (code: string) => onArticleScan(code),
    })
    const {
        handleSuccessfulMove,
        formatProductsApiData,
        findPlacementIndexByCode,
        findRemaningQuantityOfArticle,
        resetMovePlacementsActionStore,
        redirectToSelectArticlesIfNoSelection,
    } = useMovePlacementsUtilities()

    const { selectedArticles, putArticlesList } = storeToRefs(movePlacementsActionStore);

    const canSubmit = computed(() => movePlacementsActionStore.getRemainingSelectedCombinedPlace().length <= 0)

    onBeforeMount(() => {
        redirectToSelectArticlesIfNoSelection(selectedArticles.value);
        movePlacementsActionStore.setSelectedPlace(null);
    })

    const onArticleScan = async (code: string) => {
        let placementIndex = findPlacementIndexByCode(code);
        let quantityToChange = 1
        let searchCode = code

        if (placementIndex < 0) {
            const foundPackage = await showPackage(code, { findKey: 'code', with: ['combination', 'product'], })
            searchCode = foundPackage.combination ? foundPackage.combination.code : foundPackage.product.code
            quantityToChange = foundPackage.capacity ?? 1

            placementIndex = findPlacementIndexByCode(searchCode ?? '')
        }

        if (placementIndex < 0 ) {
            throw new Error("This product is not selected to move.");
        }

        if (findRemaningQuantityOfArticle(searchCode) - quantityToChange < 0) {
            throw new Error("All avaible quantity of this product is placed.");
        }

        const placement = movePlacementsActionStore.selectedArticlesCombinedPlace()[placementIndex]
        movePlacementsActionStore.putArticleOnPlace(placement, quantityToChange)
    };

    const submitMovePlacementAction = async () => {
        isLoading.value = true;

        const productsApiData = formatProductsApiData();
        const respMovePlacement = await movePlacement(productsApiData);
        const zmDocument = respMovePlacement.data as TDocument;

        resetMovePlacementsActionStore();
        handleSuccessfulMove(zmDocument);

        isLoading.value = false;
    }

    return {
        isLoading,
        canSubmit,
        handleScan: onScan,
        submitMovePlacementAction,
    };
};

export default useSelectArticlesToPut;
