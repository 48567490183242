<template>
    <section v-once>
        <h6> The process for moving products is as follows: </h6>
        <ol>
        <li>Scan the place from which you want to take products.</li>
        <li>Displayed are the products that are located at the specified location.</li>
        <li>Scan the products. At this point, they move to the "CURRENTLY SELECTED" section and their count decreases on the indicated shelf. We then set them aside.</li>
        <li>Until we press "OK," we can scan another location and continue to scan and retrieve products.</li>

        <li>NOW PROCESS CHANGES FROM TAKING TO PUTTING</li>

        <li>After clicking OK, we transition to the mode of depositing products at different locations.</li>
        <li>Similar to the earlier steps, scan a location; now, it will only display what we intend to place there.</li>
        <li>As we scan products, we place them at the scanned location.</li>
        <li>We can change locations as before. However, once a product is placed, it cannot be retrieved again.</li>
        <li>The process concludes once all products from "CURRENTLY SELECTED" are properly placed.</li>
        </ol>
    </section>
</template>