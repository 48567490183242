import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';

import AppView from '@/views/logged/AppView.vue'
import {
  dashboardRoutes,
  loginRoutes,
  scanRoutes,
  deliveryRoutes,
  movePlacementsRoutes,
  stocktakingRoutes,
} from './routes/';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/app/dashboard'
  },
  ...loginRoutes,
  {
    path: '/app',
    component: AppView,
    children: [
      {
        path: '',
        redirect: '/app/dashboard'
      },
      ...dashboardRoutes,
      ...scanRoutes,
      ...deliveryRoutes,
      ...movePlacementsRoutes,
      ...stocktakingRoutes,
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
