import { onMounted, ref } from 'vue'
import { useToast } from 'vue-toastification';
import { tryCatchAsync } from '@/utils/tryCatchAsync';
import usePlaceApi from '@/composable/api/http/usePlaceApi';
import useMovePlacementsActionStore from "@/store/movePlacementsAction";
import { TPlace } from '@/const/types/place';

const useSelectArticlesBase = ({
    onArticleScan,
    ensurePlaceIsNotBusy,
}: { 
    onArticleScan: (code: string) => any,
    ensurePlaceIsNotBusy?: boolean
}) => {
    const isLoading = ref(false)

    const movePlacementsActionStore = useMovePlacementsActionStore();
    const { showPlace } = usePlaceApi();
    const toast = useToast()

    // refactor later
    onMounted(() => {
        movePlacementsActionStore.setSelectedPlace(null)
    })

    const onError = (message: string) => {
        toast.error(message);
        isLoading.value = false
        return false
    }

    /**
     * Handles scanning for provided code and calls onPlaceScan or onArticleScan accordingly.
     * 
     * @param providedCode - The code to be scanned.
     * @returns A Promise that resolves when the scanning process is completed.
     */
    const onScan = async (providedCode: string) => {
        if (isLoading.value) {
            onError('Scanner is busy')
            return
        }

        isLoading.value = true

        const [isPlaceScanned, placeScanError] = await tryCatchAsync(() => onPlaceScan(providedCode));

        // New place selected -> success action, we can return
        if (isPlaceScanned) {
            return isLoading.value = false 
        } 
        
        if (placeScanError && !movePlacementsActionStore.selectedPlace) {
            if ((placeScanError.status ?? 500) == 404) {
                return onError('Place not found')
            }

            if (placeScanError.data) {
                if (placeScanError.data.message) {
                    return onError(placeScanError.data.message)
                }

                return onError(placeScanError.data)
            }

            return onError(placeScanError.statusText ?? placeScanError)
        }

        const [__, articleScanError] = await tryCatchAsync(() => onArticleScan(providedCode));

        if (articleScanError) {
            if (typeof articleScanError == "string") {
                return onError(articleScanError)
            }
            if (articleScanError.message ?? false) {
                return onError(articleScanError.message)
            }
            return (articleScanError.status ?? 500) == 404
                ? onError('Article not found')
                : onError(articleScanError.data?.message ?? articleScanError.toString())
        }

        return isLoading.value = false
    };

    const onPlaceScan = async (code: string) => {
        const place = await showPlace(code, {
            findKey: "code",
            with: [
                "aggregatedPlacements.product",
                "aggregatedPlacements.combination.product",
                "children",
            ],
        });

        if (place) {
            if (place.children && Array.isArray(place.children) && place.children.length > 0) {
                throw new Error("This is not a destinated place.");
            }

            const positivePlace: TPlace = {
                ...place,
                aggregatedPlacements:
                    place.aggregatedPlacements
                        .map(p => ({ ...p, quantity: Number(p.quantity) }))
                        .filter(p => p.quantity > 0),
            }

            if (place.is_busy) {
                onError("This place is busy");
                return;
            }

            movePlacementsActionStore.setSelectedPlace(positivePlace);
            return true;
        }
        return false;
    };

    return {
        onScan,
        isLoading,
    };
}

export default useSelectArticlesBase