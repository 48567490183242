import { BasicShowRequestParams } from "@/const/types/endpoint";
import useApi from "./useApi";
import { TCombination, TCombinationRelations } from "@/const/types/combination";

interface IShowCombinationParams extends BasicShowRequestParams {
    with?: TCombinationRelations[];
}

const useCombinationApi = () => {

    const { fetchOne } = useApi<TCombination>('combinations');

    /**
     * GET /index/id - returns all specific information about combination based on provided params.
     * 
     * @doc https://unlimitech.stoplight.io/docs/wms/branches/main/0b654cc298c8a-show-one-combination
     */
    const showCombination = async (key: string | number, params: IShowCombinationParams): Promise<TCombination | null> => {
        try {
            const { data: combination } = await fetchOne<IShowCombinationParams>({ key, params })

            return combination;
        } catch (error) {
            throw error
        }
    };

    return {
        showCombination,
    }
}

export default useCombinationApi