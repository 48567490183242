import { onMounted, onUnmounted, ref } from 'vue';
import { ConnectionStatus, Network } from '@capacitor/network';
import { PluginListenerHandle } from '@capacitor/core';

interface props {
    onNetworkStatusChange?: (status: ConnectionStatus) => void;
}

/**
 * Hook that allows operations connected with network status.
 *
 * @param props - Props with `onNetworkStatusChange` function that will be called on network status change.
 */
const useNetworkStatus = ({ onNetworkStatusChange }: props) => {
    let onNetworkStatusChangeEventHendler: Promise<PluginListenerHandle> & PluginListenerHandle; 

    /**
     * Retrieves the current network status.
     * 
     * @returns A promise that resolves to the current network status.
     */
    const getCurrentNetworkStatus = async (): Promise<ConnectionStatus> => {
        const status = await Network.getStatus();
        return status;
    };

    onMounted(() => {
        onNetworkStatusChangeEventHendler = Network.addListener(
            'networkStatusChange',
            (status: ConnectionStatus) => {
                if (onNetworkStatusChange) {
                    onNetworkStatusChange(status);
                }
            }
        );
    });

    onUnmounted(() => {
        onNetworkStatusChangeEventHendler.remove();
    })

    return {
        getCurrentNetworkStatus,
    };
};

export default useNetworkStatus;
