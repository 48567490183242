import { TCombination } from '@/const/types/combination';
import { TProduct } from '@/const/types/product';
import socketManager from '@/utils/libs/socketManager';
import { useToast } from 'vue-toastification';

type TOnUpdatePlacementQuantityData = {
  place_id: number,
  document_id: number,
  placements: {
    article: TProduct | TCombination;
    quantity: number;
  }[],
}

interface props {
  documentId: number;
  onErrorOccurs?: {
    onPlaceError?: () => void;
    onWSConnectionError?: () => void;
  };
  onUpdatePlacementQuantity?: (data: {
    status: string,
    data: TOnUpdatePlacementQuantityData,
  }) => void;
}

interface IArticleStatus {
  articleCode: string;
}

interface IPlaceStatus {
  placeId: number;
}

/**
 * Provides socket event management for stocktaking-related actions such as 
 * updating or resetting article quantities, and joining or leaving a document room.
 * 
 * @param {number} documentId - The ID of the current document being managed.
 * @param {IErrorHandlerOptions} onErrorOccurs - Optional callbacks for handling specific errors.
 * @returns A set of methods to interact with the WebSocket and perform document-related actions.
 */
const useStocktakingSocketEvents = ({ documentId, onErrorOccurs, onUpdatePlacementQuantity }: props) => {
  const toast = useToast();
  const globalSocket = socketManager.getSocket('/')

  const errorHandler = ({ message, module: errorModule, name }) => {
    if (name && name === 'wrong-place') {
      if (onErrorOccurs)
      onErrorOccurs?.onPlaceError()
    }

    toast.error(message);
  };

  const connectionErrorHandler = (error) => {
    console.error('Connection Error:', error);
    let errorMessage = 'Problem z połączeniem do pracy w trybie live. Spróbuj wyjść i wejść ponownie za chwilę.';
    if (error.message) {
      errorMessage += ` Szczegóły: ${error.message}`;
    }

    if (onErrorOccurs)
    onErrorOccurs?.onWSConnectionError()

    toast.error(errorMessage);
  }

  /**
   * Requests the current placement status for a given place.
   * 
   * @param params Details of the place for which to retrieve status.
   */
  const getPlacePlacementsStatus = ({ placeId }: IPlaceStatus) => {
    globalSocket.emit(
      'document/register_placements:place_status',
      {
        document_id: documentId,
        place_id: placeId
      }
    )
  }

  /**
   * Method to reset all article quantities for a given place.
   * 
   * @param params Details of the place for which to reset quantities.
   */
  const clearPlacementsForPlace = ({ placeId, articleCode }: IPlaceStatus & { articleCode?: string }) => {
    const basePayload = {
      document_id: documentId,
      place_id: placeId,
    };

    if (articleCode) {
      const payload = Object.assign({}, basePayload, { code: articleCode });

      globalSocket.emit('document/register_placements:reset_quantity', payload);
      return
    }

    globalSocket.emit('document/register_placements:reset_quantities', basePayload);
  };

  /**
   * Method to increase the quantity of an article placement.
   * 
   * @param params Combined article status and place status details.
   */
  const increaseArticlePlacementQuantity = ({ articleCode, placeId }: IArticleStatus & IPlaceStatus) => {
    globalSocket.emit('document/register_placements:update_quantity', {
      document_id: documentId,
      article_code: articleCode,
      place_id: placeId,
    })
  }

  /**
   * Method to set the quantity of an article placement.
   * 
   * @param params Combined article status and place status details.
   */
  const setArticlePlacementQuantity = ({ articleCode, placeId, quantity }: IArticleStatus & IPlaceStatus & { quantity: number }) => {
    globalSocket.emit(
      'document/register_placements:set_quantity',
      {
        document_id: documentId,
        place_id: placeId,
        code: articleCode,
        quantity,
      })
  }

  /**
   * Sets up socket event listeners for managing stocktaking activities. This includes
   * handling connection errors, custom error occurrences, and updates to article placements.
   */
  const setupSocketListeners = () => {
    if (!documentId) {
      throw new Error('Missing documentId props value')
    }

    globalSocket.on('connect_error', connectionErrorHandler);
    globalSocket.on('errors/error_occured', errorHandler);
    globalSocket.on('document/register_placements:update_quantity', onUpdatePlacementQuantity);

    // Ensure the current document is part of its room for targeted updates
    if (globalSocket.connected) {
      globalSocket.emit('document/register_placements:join', { document_id: documentId });
    } else {
      // If not initially connected, join the room upon connection
      globalSocket.on('connect', () => {
        globalSocket.emit('document/register_placements:join', { document_id: documentId });
      })
    }
  }

  /**
   * Removes socket event listeners to prevent memory leaks and ensure
   * that handlers are properly cleaned up when the component using this hook is unmounted.
   * Additionally, emits an event to leave the document's room, signaling that this client
   * is no longer interested in receiving updates for this document.
   */
  const removeSocketListeners = () => {
    globalSocket.off('errors/error_occured', errorHandler);
    globalSocket.off('document/register_placements:update_quantity', onUpdatePlacementQuantity);
    globalSocket.emit('document/register_placements:leave', { document_id: documentId });
    globalSocket.off('connect_error', connectionErrorHandler);
  }

  return {
    increaseArticlePlacementQuantity,
    getPlacePlacementsStatus,
    clearPlacementsForPlace,
    setArticlePlacementQuantity,
    setupSocketListeners,
    removeSocketListeners,
  };
};

export default useStocktakingSocketEvents;
