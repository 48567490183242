import { createI18n } from 'vue-i18n'

import * as enGB from '@/const/i18n/en-GB.json'
import * as plPL from '@/const/i18n/pl-PL.json'
import * as uaUA from '@/const/i18n/ua-UA.json'

const i18n = createI18n({
    legacy: false,
    locale: 'en-GB',
    globalInjection: true,
    messages: {
        'en-GB': { ...enGB },
        'pl-PL': { ...plPL },
        'ua-UA': { ...uaUA },
    },
})

export default i18n