import { RouteRecordRaw } from "vue-router"
import SelectEntryDocumentView from '@/views/logged/delivery/SelectEntryDocumentView.vue'
import CreateDeliveryDocumentView from '@/views/logged/delivery/CreateDeliveryDocumentView.vue'

const scanRoutes: RouteRecordRaw[] = [
    {
        name: "delivery/select-entry-document",
        path: "delivery/select-entry-document",
        component: SelectEntryDocumentView,
        meta: { requireAuth: true, }
    },
    {
        name: "delivery/create-delivery-document",
        path: "delivery/create-delivery-document/:id",
        component: CreateDeliveryDocumentView,
        meta: { requireAuth: true, }
    },
]

export default scanRoutes