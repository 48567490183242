import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Custom libs */
// Toasts
import Toast from "vue-toastification";
import { defaultOptions } from "./utils/libs/toast"
import "vue-toastification/dist/index.css";
// Pinia
import { createPinia } from 'pinia'

// Scanner
import { registerPlugin } from '@capacitor/core';
registerPlugin('CordovaIntentPlugin');

// i18n
import i18n from './utils/libs/i18n';

const pinia = createPinia()

const app = createApp(App)
  .use(IonicVue)
  .use(router)
  .use(i18n)
  .use(Toast, defaultOptions)
  .use(pinia);

declare global {
  interface Window {
    cordova: any;
    plugins: any;
  }
}

router.isReady().then(() => {
  app.mount('#app')
});