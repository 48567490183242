import { computed, onMounted } from 'vue';
import { storeToRefs } from 'pinia';
import useMovePlacementsActionStore from '@/store/movePlacementsAction';
import useSelectArticlesBase from './useSelectArticlesBase';
import useMovePlacementsUtilities from './useMovePlacementsUtilities';
import usePlaceApi from '@/composable/api/http/usePlaceApi';

const useSelectArticlesToMove = () => {
    const movePlacementsActionStore = useMovePlacementsActionStore();
    const { onScan, isLoading } = useSelectArticlesBase({
        onArticleScan: (code: string) => onArticleScan(code),
    })
    const { findPlacement, fetchPlacementDetails } = useMovePlacementsUtilities()
    const { showPlace } = usePlaceApi();

    const {
        selectedArticles,
        selectedPlace,
    } = storeToRefs(movePlacementsActionStore);

    onMounted(() => {
        selectedArticles.value = {}
    })

    const canSubmit = computed(() => Object.values(selectedArticles.value).flat().length > 0);

    const onArticleScan = async (code: string) => {
        const placementDetails = await fetchPlacementDetails(code)

        if (!placementDetails || (placementDetails.quantity ?? 0) <= 0) {
            throw new Error('Article not found.');
        }

        if (placementDetails.place_busy) {
            throw new Error('Selected place is busy.');
        }

        const searchCode = placementDetails.combination_code ?? placementDetails.product_code

        const placement = findPlacement(searchCode);

        movePlacementsActionStore.addArticleToList(placement, placementDetails.package_capacity ?? 1);
    }

    const handleTakeAllArticlesFromPlace = () => {
        if (isLoading.value || !selectedPlace.value) return;

        movePlacementsActionStore.placeCompactTakePlacements().forEach((placement) => {
            const quantity = movePlacementsActionStore.getRemainingArticleQuantityForPlace(
                placement,
            );

            movePlacementsActionStore.addArticleToList(placement, quantity);
        });
    }

    return {
        isLoading,
        canSubmit,
        handleScan: onScan,
        handleTakeAllArticlesFromPlace,
    };
};

export default useSelectArticlesToMove;