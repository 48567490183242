import axios from "@libs/axios"
import { TDeliveryDocumentType, TDocument } from "@/const/types/documents";
import { IVeryfiedPlacement } from "@/const/types/placement";

const useActionsApi = () => {

    /**
     * POST /accept-delivery - pass articles list with codes&quantities to inform system about accepted delivery.
     * 
     * @doc https://unlimitech.stoplight.io/docs/wms/branches/main/c0f8bffcea535-accept-delivery
     */
    const acceptDelivery = async ({ products, documentType, documentId, placeCode }: {
        products: { articleCode: string; quantity: number; }[];
        documentType: TDeliveryDocumentType;
        documentId: number | string;
        placeCode: string;
    }) => {
        try {
            const { data } = await axios.post('actions/accept-delivery', { products, documentType, documentId, placeCode })

            return data.data as TDocument
        } catch (error) {
            throw error
        }
    }

    /**
     * GET /verify-placement - get basic information about placement by providing article&place code.
     * 
     * @doc https://unlimitech.stoplight.io/docs/wms/branches/main/77e1ee83b87bf-verify-placement
     */
    const verifyPlacement = async ({ articleCode, placeCode }: {
        articleCode: string;
        placeCode: string;
    }) => {
        if (!articleCode || !placeCode) { throw new Error('Place code & article code are required.') }

        try {
            const { data: placement } = await axios.get<IVeryfiedPlacement>('actions/verify-placement', { params: { articleCode, placeCode } })

            return placement
        } catch (error) {
            throw error
        }
    }

    /**
     * POST /move-placement - Provide articles code & place codes to change localization in storage.
     * 
     * @doc https://unlimitech.stoplight.io/docs/wms/df71df401f02f-move-placements
     */
    const movePlacement = async (products: { articleCode: string, placeCode: string, quantity: number }[]) => {
        try {
            const { data } = await axios.post('actions/move-placement', { products })

            return data
        } catch (error) {
            throw error
        }
    }

    return {
        acceptDelivery,
        verifyPlacement,
        movePlacement,
    }
}

export default useActionsApi