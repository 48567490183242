import { BasicShowRequestParams } from "@/const/types/endpoint";
import { TPackage, TPackageRelations } from "@/const/types/package";
import useApi from "./useApi";

interface IShowPackageParams extends BasicShowRequestParams {
    with?: TPackageRelations[];
}

const usePackageApi = () => {

    const { fetchOne } = useApi<TPackage>('packages');

    /**
     * GET /index/id - returns all specific information about package based on provided params.
     * 
     * @doc https://unlimitech.stoplight.io/docs/wms/branches/main/86aea7a6a0939-show-one-package
     */
    const showPackage = async (key: string | number, params?: IShowPackageParams): Promise<TPackage | null> => {
        try {
            const { data: articlePackage } = await fetchOne<IShowPackageParams>({ key, params })

            return articlePackage;
        } catch (error) {
            throw error
        }
    };

    return {
        showPackage,
    }
}

export default usePackageApi