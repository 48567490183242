import { RouteRecordRaw } from "vue-router"

import SelectArticlesToMoveView from '@/views/logged/move-placements/SelectArticlesToMoveView.vue'
import PutArticlesToMoveView from '@/views/logged/move-placements/PutArticlesToMoveView.vue'

const scanRoutes: RouteRecordRaw[] = [
    {
        name: "move-placements/select-articles",
        path: "move-placements/select-articles",
        component: SelectArticlesToMoveView,
        meta: { requireAuth: true, }
    },
    {
        name: "move-placements/put-articles",
        path: "move-placements/put-articles",
        component: PutArticlesToMoveView,
        meta: { requireAuth: true, }
    },
]

export default scanRoutes