import { Ref, computed, ref, unref, } from "vue"
import { defineStore } from "pinia"
import { TPlace } from "@/const/types/place";
import { IAggregatedPlacement, IAggregatedPlacementCompact } from "@/const/types/placement";
import useCompactPlacements from "@/composable/useCompactPlacements";

interface IArticlesOnPlace {
    [key: string]: IAggregatedPlacementCompact[];
}

const getPlaceArticleList = (placeList: IArticlesOnPlace, placeCode: string)
    : IAggregatedPlacementCompact[] => (placeList[placeCode] || (placeList[placeCode] = []));

const generateName = (placement: IAggregatedPlacement): string => (
    placement.product
        ? placement.product.name
        : `${placement.combination.product.name} | ${placement.combination.name}`
);

const generateImageUrl = (placement: IAggregatedPlacement): string => (
    placement.product
        ? placement.product.image_url
        : placement.combination.image_url
);

const createNewArticle = (placement: IAggregatedPlacement, code: string): IAggregatedPlacementCompact => ({
    ...placement,
    quantity: 0,
    code,
    name: generateName(placement),
    imageUrl: generateImageUrl(placement),
});

const incrementArticleQuantity = (article: IAggregatedPlacementCompact, addQuantity: number): IAggregatedPlacementCompact => {
    article.quantity += Number(addQuantity);
    return article;
};

const useMovePlacementsActionStore = defineStore('move-placements-action', () => {
    const selectedPlace = ref<TPlace | null>(null)

    // Todo: refactor to takenArticlesList
    const selectedArticles = ref<IArticlesOnPlace>({})
    const putArticlesList = ref<IArticlesOnPlace>({})

    const { convertToCompactList } = useCompactPlacements();

    const placeCompactTakePlacements = () => {
        return selectedPlace.value ? convertToCompactList(selectedPlace.value.aggregatedPlacements ?? []) : []
    };
    const placeCompactPutPlacements = () => (
        selectedPlace.value ? getPlaceArticleList(
            putArticlesList.value,
            selectedPlace.value.code
        ) : []
    );

    const selectedArticlesCombinedPlace = (): IAggregatedPlacementCompact[] => {
        const articlesFromAllPlaces = Object.values(selectedArticles.value).flat();
        return articlesFromAllPlaces.reduce((acc: IAggregatedPlacementCompact[], article: IAggregatedPlacementCompact) => {
            const existingIndex = acc.findIndex((article2: IAggregatedPlacementCompact) => article.code == article2.code);
            if (existingIndex >= 0) {
                acc[existingIndex].quantity += article.quantity;
            } else {
                acc.push(structuredClone(article));
            }
            return acc;
        }, []);
    };

    const getRemainingSelectedCombinedPlace = () => {
        return selectedArticlesCombinedPlace().map(article => {
            const articlesFromAllPlaces = Object.values(putArticlesList.value).flat();
            const putArticlesCombinedPlace = articlesFromAllPlaces.reduce((acc, article) => {
                const existingIndex = acc.findIndex((article2) => article.code == article2.code);
                if (existingIndex >= 0) {
                    acc[existingIndex].quantity += article.quantity;
                } else {
                    acc.push(structuredClone(article));
                }
                return acc;
            }, []);

            const putArticleIndex = putArticlesCombinedPlace.findIndex(putArticle => putArticle.code == article.code)
            let quantity = article.quantity

            if (putArticleIndex >= 0) {
                quantity -= putArticlesCombinedPlace[putArticleIndex].quantity
            }

            return {
                ...article,
                quantity,
            }
        }).filter(article => article.quantity > 0)
    }

    const setSelectedPlace = (place: TPlace | null) => selectedPlace.value = place

    // Todo: refactor name
    const addArticleToList = (placement: IAggregatedPlacement, quantityToAdd: number = 1) => {
        if (!selectedPlace.value) {
            return;
        }

        const placeArticleList = getPlaceArticleList(
            selectedArticles.value,
            selectedPlace.value.code
        );

        const code = placement.product ? placement.product.code : placement.combination.code

        let articleInSelectedList = placeArticleList.find(
            (article) => article.code === code
        );
        const placeArticle = placeCompactTakePlacements().find(
            (article) => article.code === code
        );

        const remainingQuantity = getRemainingArticleQuantityForPlace(placeArticle)
        if (remainingQuantity - quantityToAdd < 0) {
            if (quantityToAdd > 1) {
                throw new Error(`There are no ${quantityToAdd} pieces of this article on the shelf.`)
            }
            throw new Error('This article is not on the shelf.')
        }

        if (!articleInSelectedList) {
            articleInSelectedList = createNewArticle(placement, code)

            placeArticleList.push(articleInSelectedList);
        }

        incrementArticleQuantity(articleInSelectedList, quantityToAdd);
    };

    const putArticleOnPlace = (placement: IAggregatedPlacement, quantityToAdd: number = 1) => {
        if (!selectedPlace.value) {
            return;
        }

        const placeArticleList = getPlaceArticleList(
            putArticlesList.value,
            selectedPlace.value.code
        );

        const code = placement.product ? placement.product.code : placement.combination.code

        let articleInSelectedList = placeArticleList.find(
            (article) => article.code === code
        );

        if (!articleInSelectedList) {
            articleInSelectedList = createNewArticle(placement, code)

            placeArticleList.push(articleInSelectedList);
        }

        incrementArticleQuantity(articleInSelectedList, quantityToAdd);
    };

    const getRemainingArticleQuantityForPlace = (article: IAggregatedPlacementCompact, placeProxy: Ref<TPlace> | TPlace = selectedPlace) => {
        const place = unref(placeProxy)

        if (!place) return 0;

        const placeArticleList = selectedArticles.value[selectedPlace.value.code];

        if (!placeArticleList) return article.quantity;

        const articleInPlace = placeArticleList.find((scannedArticle) => scannedArticle.code == article.code) ?? { quantity: 0 }

        return article.quantity - articleInPlace.quantity;
    };

    return {
        selectedPlace,
        selectedArticles,
        selectedArticlesCombinedPlace,
        putArticlesList,
        getRemainingSelectedCombinedPlace,

        placeCompactTakePlacements,
        placeCompactPutPlacements,

        setSelectedPlace,
        addArticleToList,
        putArticleOnPlace,
        getRemainingArticleQuantityForPlace,
    }
})

export default useMovePlacementsActionStore 