import { Ref, ref, reactive, onMounted } from "vue";
import axios from "axios"
import { useRouter, useRoute } from "vue-router";
import { useToast } from "vue-toastification";
import useUserStore from "@/store/user";
import socketManager from "@/utils/libs/socketManager";

export enum LOGIN_STAGES {
    PROVIDE_DOMAIN = 0,
    AUTHORIZE_USER = 1,
}
        
const useLogin = () => {
    const loginStage: Ref<LOGIN_STAGES> = ref(LOGIN_STAGES.PROVIDE_DOMAIN);
    const userStore = useUserStore()

    const formsDetails = reactive({
        [LOGIN_STAGES.PROVIDE_DOMAIN]: { isLoading: false, },
        [LOGIN_STAGES.AUTHORIZE_USER]: { isLoading: false, },
    })

    const router = useRouter()
    const route = useRoute()
    const toast = useToast()

    onMounted(() => {
        if (route.params.loginStage && !Array.isArray(route.params.loginStage))
            loginStage.value = Number(route.params.loginStage)
    })

    const submitDomainForm = async (inputsValues) => {
        const currentForm = formsDetails[LOGIN_STAGES.PROVIDE_DOMAIN]

        currentForm.isLoading = true

        axios
            .get(`https://${inputsValues.workingMode}.${inputsValues.wmsName}.wms.unlimitech.dev/`, )
            .then(({ data }) => {
                if (data.name === 'wms-api' && data.status === 'working') {
                    localStorage.setItem('api-domain', `https://${inputsValues.workingMode}.${inputsValues.wmsName}.wms.unlimitech.dev/mobile/v1`)
                    loginStage.value = LOGIN_STAGES.AUTHORIZE_USER

                    try {
                        socketManager.initializeManager({})
                    } catch (error) {
                        console.error(error)
                    }

                    router.replace({ name: 'login', params: { loginStage: LOGIN_STAGES.AUTHORIZE_USER } })

                    toast.success('System WMS odczytany pomyślnie')
                    return
                } else {
                    toast.error('Wprowadzone dane są nieprawidłowe')
                    return
                }
            })
            .catch((error) => {
                toast.error('Wprowadzona nazwa jest nieprawidłowa')
            })
            .finally(() => {
                currentForm.isLoading = false
            })
    }

    const submitUserCredentialForm = async (inputsValues) => {
        const domain = localStorage.getItem('api-domain')
        const currentForm = formsDetails[LOGIN_STAGES.AUTHORIZE_USER]

        currentForm.isLoading = true

        axios
            .post(`${domain}/auth/login`, inputsValues)
            .then(({ data }) => {
                userStore.actions.updateUserDataFromLoginRequest(data)
                toast.success('Pomyślnie zalogowano')

                // To bugfix: Sometimes login screen is flashing with dashboard when success
                router.push({ name: 'dashboard' })
            })
            .catch((error) => {
                console.error(error)
                if (axios.isAxiosError(error))  {
                    const errorMessage = error.response.data.message ?? error.message
                    toast.error(errorMessage)
                } else {
                    toast.error('Wprowadzone dane są nieprawidłowe')
                }
            })
            .finally(() => {
                currentForm.isLoading = false
            })
    }

    const onSubmitHandlers = {
        [LOGIN_STAGES.PROVIDE_DOMAIN]: submitDomainForm,
        [LOGIN_STAGES.AUTHORIZE_USER]: submitUserCredentialForm,
    }

    return {
        loginStage,
        formsDetails,
        onSubmitHandlers,
    }
}

export default useLogin