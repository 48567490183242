import useCloseStocktakingDocumentAlert from "@/composable/stocktaking/useCloseStocktakingDocumentAlert";
import useChangeArticleQuantityAlert from "@/composable/useChangeArticleQuantityAlert";
import useDeleteSingleArticle from "@/composable/useDeleteSingleArticle";
import { IAlertConfig } from "@/const/types/alert";
import { Ref } from "vue";

type TCustomArticles = Ref<{ code: string; }[]> | { code: string; }[]

type TAlertsConfigurationsParams = {
  changeArticleQuantity: (code: string, quantity: number) => void;
  deleteArticleQuantity: (code: string) => void;
}

/**
 * A hook designed to set up alert configurations for the start stocktaking process.
 * Utilizes given articles and alert configuration functions to generate a collection
 * of alert configurations to be used within the stocktaking UI.
 * 
 * @param documentId - The ID of the document for which alerts are being configured.
 * @param articles - Articles involved in the stocktaking process, can be a reactive Ref array or a plain array.
 * @param configParams - Configuration functions for changing and deleting article quantities.
 * @returns {Object} An object containing alert configurations and functions for displaying specific alerts.
 */
const useSetupStartStocktakingAlerts = (
    documentId: number,
    articles: TCustomArticles,
    { changeArticleQuantity, deleteArticleQuantity }: TAlertsConfigurationsParams
) => {
    const {
        alertButton: closeDocumentAlertButtons,
        isOpenAlert: isCloseDocumentAlertOpen,
        closeCloseDocumentAlert,
        openCloseDocumentAlert,
    } = useCloseStocktakingDocumentAlert(documentId);

    const {
        alertButton: changeQuantityAlertButtons,
        alertInputs: changeQuantityAlertInputFields,
        isOpenAlert: isChangeQuantityAlertOpen,
        closeChangeQuantityAlert,
        openChangeQuantityAlert
    } = useChangeArticleQuantityAlert(
        articles,
        (code: string, quantity: number) => changeArticleQuantity(code, quantity)
    );

    const {
        alertButton: deleteArticleAlertButtons,
        isOpenAlert: isDeleteArticleAlertOpen,
        closeDeleteSingleArticleAlert: closeDeleteArticleAlert,
        openDeleteSingleArticleAlert
    } = useDeleteSingleArticle(articles, (code: string) => deleteArticleQuantity(code));

    const alertConfigs: IAlertConfig[] = [
        {
            id: 'close-action',
            header: 'This action will close document AS',
            buttons: closeDocumentAlertButtons,
            isOpen: isCloseDocumentAlertOpen,
            didDismiss: closeCloseDocumentAlert,
        },
        {
            id: 'change-quantity',
            header: 'Change quantity of scanned product.',
            buttons: changeQuantityAlertButtons,
            inputs: changeQuantityAlertInputFields,
            isOpen: isChangeQuantityAlertOpen,
            didDismiss: closeChangeQuantityAlert,
        },
        {
            id: 'remove-article',
            header: 'Are you sure you want to remove that product from document AS?',
            buttons: deleteArticleAlertButtons,
            isOpen: isDeleteArticleAlertOpen,
            didDismiss: closeDeleteArticleAlert,
        },
    ]

    return {
        alertConfigs,
        displayAlertHandlers: {
            openChangeQuantityAlert,
            openDeleteSingleArticleAlert,            
            openCloseDocumentAlert,
        }
    }
}

export default useSetupStartStocktakingAlerts;
