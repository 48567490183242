import { BasicShowRequestParams } from "@/const/types/endpoint";
import { TPlace, TPlaceRelations } from "@/const/types/place";
import useApi from "./useApi";

export interface IShowPlaceParams extends BasicShowRequestParams {
    with?: TPlaceRelations[];
}

const usePlaceApi = () => {

    const { fetchOne } = useApi<TPlace>('places');

    /**
     * GET /index/id - returns all specific information about place based on provided params.
     * 
     * @doc https://unlimitech.stoplight.io/docs/wms/branches/main/43cf24e0dea57-show-one-place
     */
    const showPlace = async (key: string | number, params: IShowPlaceParams): Promise<TPlace | null> => {
        try {
            const { data: place } = await fetchOne<IShowPlaceParams>({ key, params })

            return place;
        } catch (error) {
            throw error
        }
    };

    return {
        showPlace,
    }
}

export default usePlaceApi