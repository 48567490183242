import { defineStore } from 'pinia';
import { reactive, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router';
import { useToast } from 'vue-toastification';
import { TUser } from './../const/types/user';
import { LOGIN_STAGES } from '../views/auth/login/useLogin';
import axios from './../utils/libs/axios'
import { default as routerMain }  from './../router'

interface IState {
    token: string;
    user?: TUser
}

const useUserStore = defineStore('user', () => {
    const state = reactive<IState>({
        token: '',
        user: null,
    })

    const toast = useToast()
    const router = useRouter()

    onMounted(() => {
        state.token = JSON.parse(localStorage.getItem('wms-token'))
        state.user = JSON.parse(localStorage.getItem('wms-user'))

        const route = useRoute() 

        if (!state.token && route.meta && route.meta.requireAuth) {
            router.replace({ name: 'login' })

            return
        }
    })

    const updateUserDataFromLoginRequest = ({ data }) => {
        state.token = data.token
        state.user = data.user

        localStorage.setItem('wms-token', JSON.stringify(state.token))
        localStorage.setItem('wms-user', JSON.stringify(state.user))
    }

    const fetchActiveUser = async () => {
        try {
            const isDomainSet = Boolean(localStorage.getItem('api-domain'))

            if (!isDomainSet) throw new Error('Domain is not set');

            const { data } = await axios.get('auth/user')
            const { data: user } = data as { data: TUser }
             
            if (user) {
                localStorage.setItem('wms-user', JSON.stringify(user))
                state.user = user
            }
        } catch (error) {
            console.error(error)
            const route = useRoute()

            if (route && route.name != 'login')
                logout(false)
        }
    }

    const logout = (showNotification = true) => {
        state.token = ''
        state.user = null

        localStorage.removeItem('wms-token')
        localStorage.removeItem('wms-user')

        if (showNotification)
            toast.success('Pomyślnie wylogowano!')

        routerMain.isReady().then(() => {
            routerMain.push({ name: 'login', params: { loginStage: LOGIN_STAGES.AUTHORIZE_USER } })
        })
    }

    return {
        state,
        actions: {
            logout,
            fetchActiveUser,
            updateUserDataFromLoginRequest,
        },
    }
})

export default useUserStore