import { computed, reactive, ref } from "vue";
import { TDocument, TDocumentRelations } from "@/const/types/documents";
import useDocumentApi from "@/composable/api/http/useDocumentApi";
import useDocumentArticles from "@/composable/useDocumentArticles";
import { InfiniteScrollCustomEvent } from "@ionic/vue";
import { TPlace } from "@/const/types/place";

interface IArticlesModal {
  isOpen: boolean;
  isLoading: boolean;
  documentRef: TDocument | null
} 

interface IDocumentPaginatedByAggregatedPlacements extends TDocument {
    aggregated_placements_count: number;
}
const useAggregationPlacementModal = ( wantedDocumentRelation: TDocumentRelations[], wantedPerPage: number) => {

    const { showDocument } = useDocumentApi()
    const { getArticles } = useDocumentArticles()
    const wantedParams = { perPage: { 'placementsRelatedForZD': wantedPerPage }, with: wantedDocumentRelation };

    const articlesModal = reactive<IArticlesModal>({ isOpen: false, documentRef: null, isLoading: false })
    const selectedDocumentId = ref(-1)
    const currentPage = ref(1)

    const articlesModalItems = computed(() => getArticles(articlesModal.documentRef))

    const canLoadMoreItems = computed(() => {
        if (!articlesModal.documentRef) return false
        return articlesModal.documentRef.placementsRelatedForZD.length < (articlesModal.documentRef as IDocumentPaginatedByAggregatedPlacements).aggregated_placements_count
    })

    const storeEntriesData = async (documentId: number) => {
        articlesModal.isOpen = true;
        articlesModal.isLoading = true;
        articlesModal.documentRef = null;
      
        const data = await showDocument(documentId, { page: { 'placementsRelatedForZD': 1 }, ...wantedParams }) as IDocumentPaginatedByAggregatedPlacements;
        selectedDocumentId.value = documentId;
        currentPage.value = 1;
        articlesModal.documentRef = data;
        articlesModal.isLoading = false;
        return data;
      };
    
    const loadNextRows = async (ev: InfiniteScrollCustomEvent) => {
        if (!canLoadMoreItems.value) {
            ev.target.complete()
            return
        }

        currentPage.value += 1;
        const data = await showDocument(selectedDocumentId.value, { page: { 'placementsRelatedForZD': currentPage.value }, ...wantedParams }) as IDocumentPaginatedByAggregatedPlacements

        articlesModal.documentRef.aggregatedPlacements = [...articlesModal.documentRef.aggregatedPlacements, ...data.aggregatedPlacements]

        ev.target.complete()
        return
    }

    return {
        articlesModal,
        loadNextRows,
        canLoadMoreItems,
        articlesModalItems,
        storeEntriesData,
    }
}

export default useAggregationPlacementModal