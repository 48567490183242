import { IAggregatedPlacement, IAggregatedPlacementCompact } from "@/const/types/placement"
import { Ref, unref } from "vue"

const useCompactPlacements = () => {
    const convertToCompactList = (placementsProxy: IAggregatedPlacement[] | Ref<IAggregatedPlacement[]>): IAggregatedPlacementCompact[] => {
        const placements = unref(placementsProxy)

        if (!placements) return []

        const documentProducts: IAggregatedPlacementCompact[] = placements
            .filter(placement => placement.product_id)
            .map(placement => ({
                ...placement,
                type: 'product',
                code: placement.product!.code,
                name: placement.product!.name,
                imageUrl: placement.product!.image_url,
            }))

        const documentCombinations: IAggregatedPlacementCompact[] = placements
            .filter(placement => placement.combination_id)
            .map(placement => ({
                ...placement,
                type: 'combination',
                code: placement.combination!.code,
                name: `${placement.combination?.product!.name} | ${placement.combination!.name}`,
                imageUrl: placement.combination!.image_url,
            }))

        return [...(documentProducts ?? []), ...(documentCombinations ?? [])]
    }

    const convertToCompactOne = (placementProxy: IAggregatedPlacement | Ref<IAggregatedPlacement>): IAggregatedPlacementCompact => {
        const placement = unref(placementProxy)

        return convertToCompactList([placement])[0]
    }

    return {
        convertToCompactList,
        convertToCompactOne,
    }
}

export default useCompactPlacements